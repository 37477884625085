import Vue from 'vue'

import Qs from 'qs'

class Files {
  async search({params}) {
    return await Vue.axios
      .get('app/files/search', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/files/' + id)
  }
}

const files = new Files()

export default files
