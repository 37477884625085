var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"icon":_vm.icon,"title":title,"hasFilter":_vm.hasFilter,"colspan":_vm.colspan,"empty":_vm.empty,"defaultFilters":_vm.defaultFilters,"defaultOrder":_vm.defaultOrder,"defaultBy":_vm.defaultBy,"defaultLimit":_vm.defaultLimit,"fetchData":_vm.fetchData,"itemsKey":_vm.itemsKey},scopedSlots:_vm._u([{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})],1)]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ComboboxRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.method,"outlined":true,"dense":true,"hide-details":"auto","label":"Metóda","clearable":true,"indexGroupValue":"audit_log_method"},on:{"change":setFilter},model:{value:(params.Filters.method),callback:function ($$v) {_vm.$set(params.Filters, "method", $$v)},expression:"params.Filters.method"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ComboboxRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.statusCode,"outlined":true,"dense":true,"hide-details":"auto","label":"Stavový kód","clearable":true,"indexGroupValue":"audit_log_status_code"},on:{"change":setFilter},model:{value:(params.Filters.statusCode),callback:function ($$v) {_vm.$set(params.Filters, "statusCode", $$v)},expression:"params.Filters.statusCode"}})],1)]}},{key:"list-head",fn:function({
            order,
            by,
            setOrderBy
          }){return [_c('th',[_vm._v(" Uri ")]),_c('th',[_vm._v(" Užívateľ ")]),_c('th',[_c('OrderByList',{attrs:{"label":"Dátum a čas požiadavky","value":"audlogdat.createdAt","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Metóda ")]),_c('th',[_c('OrderByList',{attrs:{"label":"Dátum a čas odpovede","value":"audlogdat.updatedAt","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Stavový kód ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item
          }){return [_c('td',[_c('a',{attrs:{"href":Item.uri,"target":"_blank"}},[_c('TruncateHelper',{attrs:{"title":Item.uri,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.uri)+" ")])],1)]),_c('td',[_c('SpanIsEmpty',{attrs:{"value":Item.userName}},[_c('TruncateHelper',{attrs:{"title":Item.userName,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.userName)+" ")])],1)],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_vm._v(" "+_vm._s(Item.method)+" ")]),_c('td',[_c('SpanIsEmpty',{attrs:{"value":Item.updatedAt}},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.updatedAt,'DD.MM.YYYY HH:mm'))+" ")])],1),_c('td',[_c('SpanIsEmpty',{attrs:{"value":Item.statusCode}},[_vm._v(" "+_vm._s(Item.statusCode)+" ")])],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/audit-logs/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)]}},{key:"pagination-count",fn:function({
            limit,
            setLimit
          }){return _vm._l(([10, 25, 50, 100]),function(_limit,i){return _c('LimitPagination',{key:i,attrs:{"label":_limit,"value":_limit,"limit":limit},on:{"setLimit":setLimit}})})}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }