import Vue from 'vue'

class Interviews {
  async indexStartAndEnd({start, end}) {
    return await Vue.axios
      .get('app/interviews/by-start-and-end/' + start + '/' + end)
  }

  async createDate({date, data}) {
    return await Vue.axios
      .post('app/interviews/by-date/' + date, data)
  }

  async createMoreDate({date, data}) {
    return await Vue.axios
      .post('app/interviews/more/by-date/' + date, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/interviews/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/interviews/' + id, data)
  }

  async updateStatusClosed({id, data}) {
    return await Vue.axios
      .put('app/interviews/' + id + '/status/closed', data)
  }

  async updateStatusCanceled({id, data}) {
    return await Vue.axios
      .put('app/interviews/' + id + '/status/canceled', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/interviews/' + id)
  }
}

const interviews = new Interviews()

export default interviews
