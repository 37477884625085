<template>
  <CardPart
      :icon="icon"
      :maxWidth="maxWidth"
      :title="title + ' (' + total + ')'"
      :subtitle="subtitle"
  >
    <template
        v-slot:action
    >
      <v-spacer/>

      <slot
          name="action"
          :init="init"
      />

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click.stop.prevent="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </template>

    <template
        v-slot:default
    >
      <v-row>
        <BlockGrid
            :hasFilter="hasFilter"
            :hasSearch="hasSearch"
            @setParams="setParams"
        >
          <template
              v-slot:search="{
                setSearch
              }"
          >
            <slot
                name="search"
                :params="params"
                :errors="errors"
                :setSearch="setSearch"
            />
          </template>

          <template
              v-slot:filter="{
                setFilter
              }"
          >
            <slot
                name="filter"
                :params="params"
                :errors="errors"
                :setFilter="setFilter"
            />
          </template>
        </BlockGrid>

        <ListGrid
            :loading="loading"
            :items="items"
            :hasStandard="hasStandard"
            :standardClass="standardClass"
            :hasExtra="hasExtra"
            :extraClass="extraClass"
            :colspan="colspan"
            :empty="empty"
            @setParams="setParams"
        >
          <template
              v-slot:head="{
                setOrderBy
              }"
          >
            <slot
                name="list-head"
                :items="items"
                :order="params.order"
                :by="params.by"
                :init="init"
                :setOrderBy="setOrderBy"
            />
          </template>

          <template
              v-slot:body-standard="{
                Item
              }"
          >
            <slot
                name="list-body-standard"
                :items="items"
                :Item="Item"
                :init="init"
            />
          </template>

          <template
              v-slot:body-extra="{
                Item
              }"
          >
            <slot
                name="list-body-extra"
                :items="items"
                :Item="Item"
                :init="init"
            />
          </template>
        </ListGrid>

        <PaginationGrid
            :loading="loading"
            :total="total"
            :limit="params.limit"
            :page="params.page"
            @setParams="setParams"
        >
          <template
              v-slot:count="{
                setLimit
              }"
          >
            <slot
                name="pagination-count"
                :limit="params.limit"
                :setLimit="setLimit"
            />
          </template>
        </PaginationGrid>
      </v-row>
    </template>
  </CardPart>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  import BlockGrid from '@/components/_Part/_Grid/Block'
  import ListGrid from '@/components/_Part/_Grid/List'
  import PaginationGrid from '@/components/_Part/_Grid/Pagination'

  export default {
    name: 'Grid',
    props: {
      maxWidth: {
        type: String,
        default: 'calc(100% - 36px)'
      },
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      subtitle: {
        type: String,
        default: null
      },
      hasSearch: {
        type: Boolean,
        default: true
      },
      hasFilter: {
        type: Boolean,
        default: false
      },
      hasStandard: {
        type: Boolean,
        default: true
      },
      standardClass: {
        type: Function,
        default: () => ''
      },
      hasExtra: {
        type: Boolean,
        default: false
      },
      extraClass: {
        type: Function,
        default: () => ''
      },
      colspan: {
        type: Number,
        required: true
      },
      empty: {
        type: String,
        required: true
      },
      defaultFilters: {
        type: Object,
        default: () => {}
      },
      defaultQuery: {
        type: String,
        default: null
      },
      defaultOrder: {
        type: String,
        required: true
      },
      defaultBy: {
        type: String,
        required: true
      },
      defaultLimit: {
        type: Number,
        required: true
      },
      fetchData: {
        type: Function,
        required: true
      },
      itemsKey: {
        type: String,
        required: true
      }
    },
    emits: [
      'onSuccess',
      'onErrors',
      'onFail'
    ],
    components: {
      CardPart,
      BlockGrid,
      ListGrid,
      PaginationGrid
    },
    data() {
      return {
        loading: false,
        errors: {},
        total: 0,
        items: [],
        params: {
          Filters: {
            ...this.defaultFilters,
          },
          query: this.defaultQuery,
          order: this.defaultOrder,
          by: this.defaultBy,
          limit: this.defaultLimit,
          page: 1
        }
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        this.loading = true

        this.errors = {}

        this.fetchData(this.params)
          .then((response) => {
            this.total = response.data.data.total

            this.items = response.data.data[this.itemsKey]

            this.$emit('onSuccess', response)
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.errors = error.response.data.errors

              this.$emit('onErrors', error.response)

              this.$nextTick(() => {
                if (document.querySelector('.v-messages.error--text')) {
                  document.querySelector('.v-messages.error--text').closest('.v-input').scrollIntoView()
                }
              })
            } else {
              this.$store.dispatch('layout/openSnackbar', {
                message: error.response.data.message
              })

              this.$emit('onFail', error.response)
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      setParams(params) {
        this.params = {
          ...this.params,
          ...params
        }

        this.init()
      }
    }
  }
</script>
