<template>
  <NotBlankPage
      :maxWidth="maxWidth"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:action="{
          Item,
          reload
        }"
    >
      <Update
          :id="Item.id"
          @onUpdate="reload"
      />

      <Delete
          v-if="Item.deletable"
          :id="Item.id"
          @onDelete="() => {
            $router.push('/courses')
          }"
      />
    </template>

    <template
        v-slot:default="{
          calls,
          Item,
          reload
        }"
    >
      <v-row>
        <v-col
            v-if="Item.status === 'deactivated'"
            cols="12"
        >
          <v-alert
              type="error"
          >
            Školenie je deaktivované.
          </v-alert>
        </v-col>

        <v-col
            v-if="Item.content"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <div
                  v-html="Item.content"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              icon="mdi-attachment"
              :title="'Materiály (' + Item.materials.length + ')'"
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <ColumnsIsEmpty
                  :value="Item.materials.length > 0"
              >
                <v-col
                    v-for="(_Material) in Item.materials"
                    :key="_Material.id"
                    cols="auto"
                    align="center"
                >
                  <p>
                    <a
                        :href="$global.cdnUrl() + 'media/files/by-token/' + _Material.token"
                        target="_blank"
                    >
                      <v-icon
                          :large="true"
                      >
                        mdi-file
                      </v-icon>

                      <br/>

                      {{ _Material.name }}
                    </a>
                  </p>
                </v-col>
              </ColumnsIsEmpty>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <Quizzes
              :key="calls"
              :Course="Item"
              @reload="reload"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Courses from '@/services/courses'

  import Update from '@/components/Courses/Update'
  import Delete from '@/components/Courses/Delete'

  import Quizzes from '@/components/Course/Quizzes'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  import ColumnsIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Columns'

  export default {
    name: 'Course',
    components: {
      Update,
      Delete,
      Quizzes,
      NotBlankPage,
      CardPart,
      ColumnsIsEmpty
    },
    data() {
      return {
        maxWidth: 'calc(100% - 168px)',
        icon: 'mdi-book',
        Item: 'Course',
        failLink: '/courses'
      }
    },
    methods: {
      fetchData() {
        return Courses
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setSubtitle() {
        return 'Školenie'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Školenia',
            to: '/courses',
            exact: true
          }
        ]
      }
    }
  }
</script>
