<template>
  <v-card>
    <v-card-title
        v-if="title"
    >
      <TruncateHelper
          :title="title"
          :maxWidth="maxWidth"
      >
        <v-icon
            v-if="icon"
        >
          {{ icon }}
        </v-icon>

        {{ title }}
      </TruncateHelper>

      <slot
          name="action"
      />
    </v-card-title>

    <v-card-subtitle
        v-if="subtitle"
    >
      {{ subtitle }}
    </v-card-subtitle>

    <v-card-text>
      <slot
          name="default"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Card',
    props: {
      maxWidth: {
        type: String,
        default: '100%'
      },
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      subtitle: {
        type: String,
        default: null
      }
    },
    components: {
      TruncateHelper
    }
  }
</script>
