<template>
  <GridPart
      :maxWidth="maxWidth"
      :icon="icon"
      :title="title"
      :colspan="colspan"
      :empty="empty"
      :defaultOrder="defaultOrder"
      :defaultBy="defaultBy"
      :defaultLimit="defaultLimit"
      :fetchData="fetchData"
      :itemsKey="itemsKey"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <CreateIndexGroup
          :IndexGroup="IndexGroup"
          @onCreateIndexGroup="(response) => {
            init()

            $emit('reload', response)
          }"
      />
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-col
          cols="12"
      >
        <v-text-field
            v-model="params.query"
            :error-messages="errors.query"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Vyhľadávanie"
            :clearable="true"
            @change="setSearch"
        />
      </v-col>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          setOrderBy
        }"
    >
      <th
          class="text-left fixed"
      >
        Názov
      </th>

      <th>
        <OrderByList
            label="Poradie"
            value="inddat.sort"
            :order="order"
            :by="by"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Hodnota
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="text-left fixed"
      >
        <TruncateHelper
            :title="Item.title"
            maxWidth="300px"
        >
          {{ Item.title }}
        </TruncateHelper>
      </td>

      <td>
        {{ Item.sort }}
      </td>

      <td>
        <TruncateHelper
            :title="Item.value"
            maxWidth="300px"
        >
          {{ Item.value }}
        </TruncateHelper>
      </td>

      <td
          class="text-right fixed"
      >
        <Update
            :id="Item.id"
            @onUpdate="(response) => {
              init()

              $emit('reload', response)
            }"
        />

        <Delete
            :id="Item.id"
            @onDelete="(response) => {
              init()

              $emit('reload', response)
            }"
        />
      </td>
    </template>

    <template
        v-slot:pagination-count="{
          limit,
          setLimit
        }"
    >
      <LimitPagination
          v-for="(_limit, i) in [10, 25, 50, 100]"
          :key="i"
          :label="_limit"
          :value="_limit"
          :limit="limit"
          @setLimit="setLimit"
      />
    </template>
  </GridPart>
</template>

<script>
  import Indexes from '@/services/indexes'

  import CreateIndexGroup from '@/components/Indexes/CreateIndexGroup'
  import Update from '@/components/Indexes/Update'
  import Delete from '@/components/Indexes/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Indexes',
    props: {
      IndexGroup: {
        type: Object,
        required: true
      }
    },
    emits: [
      'reload'
    ],
    components: {
      CreateIndexGroup,
      Update,
      Delete,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper
    },
    data() {
      return {
        maxWidth: 'calc(100% - 80px)',
        icon: 'mdi-comment',
        title: 'Číselníky',
        colspan: 4,
        empty: 'Nenašli sa žiadne číselníky.',
        itemsKey: 'indexes',
        defaultOrder: 'inddat.sort',
        defaultBy: 'ASC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return Indexes
          .searchIndexGroup({
            indexGroupId: this.IndexGroup.id,
            params: params
          })
      }
    }
  }
</script>
