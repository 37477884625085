<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            v-if="Item.error"
            cols="12"
        >
          <v-alert
              type="error"
          >
            {{ Item.error }}
          </v-alert>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Od
                  </h3>

                  <p>
                    <a
                        :href="'mailto: ' + Item.fromEmail"
                    >
                      {{ Item.fromEmail }}

                      <template
                          v-if="Item.fromName"
                      >
                        ({{ Item.fromName }})
                      </template>
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Komu
                  </h3>

                  <p>
                    <a
                        :href="'mailto: ' + Item.toEmail"
                    >
                      {{ Item.toEmail }}

                      <template
                          v-if="Item.toName"
                      >
                        ({{ Item.toName }})
                      </template>
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Predmet
                  </h3>

                  <p>
                    {{ Item.subject }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Stav
                  </h3>

                  <p>
                    <v-badge
                        :inline="true"
                        :color="Item.statusColorEnum"
                        :content="Item.statusEnum"
                    />
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Dátum a čas zadania
                  </h3>

                  <p>
                    {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Dátum a čas odoslania
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.updatedAt"
                  >
                    {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
                  </ParagraphIsEmpty>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              icon="mdi-xml"
              title="HTML"
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <iframe
                  :srcdoc="Item.html"
                  width="100%"
                  height="500px"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              icon="mdi-text"
              title="Text"
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <p>
                {{ Item.text }}
              </p>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              icon="mdi-attachment"
              :title="'Prílohy (' + Item.attachments.length + ')'"
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <ColumnsIsEmpty
                  :value="Item.attachments.length > 0"
              >
                <v-col
                    v-for="(_Attachment) in Item.attachments"
                    :key="_Attachment.id"
                    cols="auto"
                    align="center"
                >
                  <p>
                    <a
                        :href="$global.cdnUrl() + 'media/files/by-token/' + _Attachment.token"
                        target="_blank"
                    >
                      <v-icon
                          :large="true"
                      >
                        mdi-file
                      </v-icon>

                      <br/>

                      {{ _Attachment.name }}
                    </a>
                  </p>
                </v-col>
              </ColumnsIsEmpty>
            </template>
          </CardPart>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Mails from '@/services/mails'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  import ColumnsIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Columns'

  import ParagraphIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Paragraph'

  export default {
    name: 'Mail',
    components: {
      NotBlankPage,
      CardPart,
      ColumnsIsEmpty,
      ParagraphIsEmpty
    },
    data() {
      return {
        icon: 'mdi-email',
        Item: 'Mail',
        failLink: '/mails'
      }
    },
    methods: {
      fetchData() {
        return Mails
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return '#' + Item.id
      },
      setSubtitle() {
        return 'Mail'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Maily',
            to: '/mails',
            exact: true
          }
        ]
      }
    }
  }
</script>
