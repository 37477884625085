<template>
  <NotBlankPage
      :maxWidth="maxWidth"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <template
          v-if="Item.id !== $store.state.auth.User.id"
      >
        <Update
            :id="Item.id"
            @onUpdate="refresh"
        />

        <Delete
            v-if="Item.deletable"
            :id="Item.id"
            @onDelete="() => {
              $router.push('/admins')
            }"
        />
      </template>
    </template>

    <template
        v-slot:default="{
          calls,
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    E-mail
                  </h3>

                  <p>
                    <a
                        :href="'mailto: ' + Item.email"
                    >
                      {{ Item.email }}
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Telefón
                  </h3>

                  <p>
                    <a
                        :href="'tel: ' + Item.phone"
                    >
                      {{ Item.phone }}
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Stav
                  </h3>

                  <p>
                    <v-badge
                        :inline="true"
                        :color="Item.statusColorEnum"
                        :content="Item.statusEnum"
                    />

                    <v-tooltip
                        v-if="Item.note"
                        :bottom="true"
                    >
                      <template
                          v-slot:activator="{
                            on,
                            attrs
                          }"
                      >
                        <span
                            v-on="on"
                            v-bind="attrs"
                        >
                          <v-icon>
                            mdi-information
                          </v-icon>
                        </span>
                      </template>

                      {{ Item.note }}
                    </v-tooltip>
                  </p>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <UserHistories
              :key="calls"
              :User="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Admins from '@/services/admins'

  import Update from '@/components/Admins/Update'
  import Delete from '@/components/Admins/Delete'

  import UserHistories from '@/components/Admin/UserHistories'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Admin',
    components: {
      Update,
      Delete,
      UserHistories,
      NotBlankPage,
      CardPart
    },
    data() {
      return {
        maxWidth: 'calc(100% - 168px)',
        icon: 'mdi-account',
        Item: 'Admin',
        failLink: '/admins'
      }
    },
    methods: {
      fetchData() {
        return Admins
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.name
      },
      setSubtitle() {
        return 'Administrátor'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Administrátori',
            to: '/admins',
            exact: true
          }
        ]
      }
    }
  }
</script>
