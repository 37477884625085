<template>
  <v-col
      v-if="hasSearch || hasFilter"
      cols="12"
  >
    <CardPart
        class="grey lighten-5"
    >
      <template
          v-slot:default
      >
        <v-row>
          <template
              v-if="hasSearch"
          >
            <slot
                name="search"
                :setSearch="setSearch"
            />
          </template>

          <template
              v-if="hasFilter"
          >
            <slot
                name="filter"
                :setFilter="setFilter"
            />
          </template>
        </v-row>
      </template>
    </CardPart>
  </v-col>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Block',
    props: {
      hasSearch: {
        type: Boolean,
        default: true
      },
      hasFilter: {
        type: Boolean,
        default: false
      }
    },
    emits: [
      'setParams'
    ],
    components: {
      CardPart
    },
    methods: {
      setSearch() {
        this.$emit('setParams', {
          page: 1
        })
      },
      setFilter() {
        this.$emit('setParams', {
          page: 1
        })
      }
    }
  }
</script>
