var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PagePart',{attrs:{"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs},scopedSlots:_vm._u([{key:"default",fn:function({
        loading,
        calls,
        title,
        subtitle,
        backLink,
        refresh,
        reload
      }){return [_vm._t("default",null,{"loading":loading,"calls":calls,"title":title,"subtitle":subtitle,"backLink":backLink,"refresh":refresh,"reload":reload})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }