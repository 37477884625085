<template>
  <DeleteConfirm
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :deleteData="deleteData"
      @onDelete="onDelete"
  />
</template>

<script>
  import Indexes from '@/services/indexes'

  import DeleteConfirm from '@/components/_Part/_Modal/_Save/_Confirm/Delete'

  export default {
    name: 'Delete',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onDelete'
    ],
    components: {
      DeleteConfirm
    },
    data() {
      return {
        itemKey: 'Index'
      }
    },
    methods: {
      fetchData() {
        return Indexes
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Odstrániť číselník'
      },
      setSubtitle(Item) {
        return Item.indexGroupTitle + ' | ' + Item.title
      },
      setAlert() {
        return 'Naozaj chcete odstrániť číselník?'
      },
      deleteData() {
        return Indexes
          .delete({
            id: this.id
          })
      },
      onDelete(response) {
        this.$emit('onDelete', response)
      }
    }
  }
</script>
