import Vue from 'vue'

class Security {
  async login({data}) {
    return await Vue.axios
      .post('security/login', data)
  }

  async logout() {
    return await Vue.axios
      .delete('security/logout')
  }
}

const security = new Security()

export default security
