<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :setTitle="setTitle"
      :setAlert="setAlert"
      :setItemForm="setItemForm"
      :saveData="updateMassStatusConfirmedData"
      @onSave="onUpdateMassStatusConfirmed"
  />
</template>

<script>
  import Volunteers from '@/services/volunteers'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'UpdateMassStatusConfirmed',
    props: {
      ids: {
        type: Array,
        required: true
      }
    },
    emits: [
      'onUpdateMassStatusConfirmed'
    ],
    components: {
      SaveModal
    },
    data() {
      return {
        color: 'success',
        icon: 'mdi-check',
        maxWidth: 500,
        buttonYes: 'Hromadne schváliť'
      }
    },
    methods: {
      setTitle() {
        return 'Hromadne schváliť dobrovoľníkov'
      },
      setAlert() {
        return 'Označené položky: ' + this.ids.length
      },
      setItemForm() {
        return {
          users: this.ids
        }
      },
      updateMassStatusConfirmedData(ItemForm) {
        return Volunteers
          .updateMassStatusConfirmed({
            data: ItemForm
          })
      },
      onUpdateMassStatusConfirmed(response) {
        this.$emit('onUpdateMassStatusConfirmed', response)
      }
    }
  }
</script>
