var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridPart',{attrs:{"icon":_vm.icon,"title":_vm.title,"colspan":_vm.colspan,"empty":_vm.empty,"defaultOrder":_vm.defaultOrder,"defaultBy":_vm.defaultBy,"defaultLimit":_vm.defaultLimit,"fetchData":_vm.fetchData,"itemsKey":_vm.itemsKey},scopedSlots:_vm._u([{key:"search",fn:function({
        params,
        errors,
        setSearch
      }){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})],1)]}},{key:"list-head",fn:function({
        order,
        by,
        setOrderBy
      }){return [_c('th',{staticClass:"text-left fixed"},[_c('OrderByList',{attrs:{"label":"Dátum vykonania","value":"attdat.createdAt","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Dobrovoľník ")]),_c('th',[_vm._v(" Minimálne hodnotenie ")]),_c('th',[_vm._v(" Výsledné hodnotenie ")]),_c('th',[_vm._v(" Výsledok ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
        Item
      }){return [_c('td',{staticClass:"text-left fixed"},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_c('router-link',{attrs:{"to":'/volunteers/' + Item.volunteerId}},[_c('TruncateHelper',{attrs:{"title":Item.volunteerName,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.volunteerName)+" ")])],1)],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("percent")(Item.minRate))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("percent")(Item.resultRate))+" ")]),_c('td',[(Item.resultSuccessful)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_vm._e(),(Item.resultUnsuccessful)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")]):_vm._e()],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/attempts/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)]}},{key:"pagination-count",fn:function({
        limit,
        setLimit
      }){return _vm._l(([10, 25, 50, 100]),function(_limit,i){return _c('LimitPagination',{key:i,attrs:{"label":_limit,"value":_limit,"limit":limit},on:{"setLimit":setLimit}})})}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }