<template>
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <v-alert
          v-if="!$store.state.auth.User.hasSectionCourses"
          type="info"
      >
        Školenia sú pre Vás nedostupné.
      </v-alert>

      <GridPart
          v-else
          :icon="icon"
          :title="title"
          :colspan="colspan"
          :empty="empty"
          :defaultOrder="defaultOrder"
          :defaultBy="defaultBy"
          :defaultLimit="defaultLimit"
          :fetchData="fetchData"
          :itemsKey="itemsKey"
      >
        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-col
              cols="12"
          >
            <v-text-field
                v-model="params.query"
                :error-messages="errors.query"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Vyhľadávanie"
                :clearable="true"
                @change="setSearch"
            />
          </v-col>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              setOrderBy
            }"
        >
          <th
              class="text-left fixed"
          >
            <OrderByList
                label="Názov"
                value="coudat.title"
                :order="order"
                :by="by"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item
            }"
        >
          <td
              class="text-left fixed"
          >
            <TruncateHelper
                :title="Item.title"
                maxWidth="300px"
            >
              {{ Item.title }}
            </TruncateHelper>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/courses-auth/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </td>
        </template>

        <template
            v-slot:pagination-count="{
              limit,
              setLimit
            }"
        >
          <LimitPagination
              v-for="(_limit, i) in [10, 25, 50, 100]"
              :key="i"
              :label="_limit"
              :value="_limit"
              :limit="limit"
              @setLimit="setLimit"
          />
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import CoursesAuth from '@/services/courses-auth'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'CoursesAuth',
    components: {
      BlankPage,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper
    },
    data() {
      return {
        icon: 'mdi-book',
        colspan: 2,
        empty: 'Nenašli sa žiadne školenia.',
        itemsKey: 'courses',
        defaultOrder: 'coudat.title',
        defaultBy: 'ASC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return CoursesAuth
          .search({
            params: params
          })
      },
      setTitle() {
        return 'Školenia'
      }
    }
  }
</script>
