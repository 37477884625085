<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                    sm="9"
                >
                  <h3>
                    Uri
                  </h3>

                  <p>
                    <a
                        :href="Item.uri"
                        target="_blank"
                    >
                      {{ Item.uri }}
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="3"
                >
                  <h3>
                    Užívateľ
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.userName"
                  >
                    {{ Item.userName }}
                  </ParagraphIsEmpty>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
            sm="6"
        >
          <v-row>
            <v-col
                cols="12"
            >
              <CardPart
                  class="grey lighten-5"
              >
                <template
                    v-slot:default
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h3>
                        Dátum a čas požiadavky
                      </h3>

                      <p>
                        {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
                      </p>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <h3>
                        Metóda
                      </h3>

                      <p>
                        {{ Item.method }}
                      </p>
                    </v-col>
                  </v-row>
                </template>
              </CardPart>
            </v-col>

            <v-col
                cols="12"
            >
              <CardPart
                  icon="mdi-share"
                  title="Požiadavka"
                  class="grey lighten-5"
              >
                <template
                    v-slot:default
                >
                  <JsonIsEmpty
                      :value="Item.request"
                      :data="Item.request"
                      :deep="0"
                  />
                </template>
              </CardPart>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            cols="12"
            sm="6"
        >
          <v-row>
            <v-col
                cols="12"
            >
              <CardPart
                  class="grey lighten-5"
              >
                <template
                    v-slot:default
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h3>
                        Dátum a čas odpovede
                      </h3>

                      <ParagraphIsEmpty
                          :value="Item.updatedAt"
                      >
                        {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
                      </ParagraphIsEmpty>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <h3>
                        Stavový kód
                      </h3>

                      <ParagraphIsEmpty
                          :value="Item.statusCode"
                      >
                        {{ Item.statusCode }}
                      </ParagraphIsEmpty>
                    </v-col>
                  </v-row>
                </template>
              </CardPart>
            </v-col>

            <v-col
                cols="12"
            >
              <CardPart
                  icon="mdi-reply"
                  title="Odpoveď"
                  class="grey lighten-5"
              >
                <template
                    v-slot:default
                >
                  <JsonIsEmpty
                      :value="Item.response"
                      :data="Item.response"
                      :deep="0"
                  />
                </template>
              </CardPart>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              icon="mdi-server"
              title="Server"
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <JsonIsEmpty
                  :value="Item.server"
                  :data="Item.server"
                  :deep="0"
              />
            </template>
          </CardPart>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import AuditLogs from '@/services/audit-logs'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  import JsonIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Json'
  import ParagraphIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Paragraph'

  export default {
    name: 'AuditLog',
    components: {
      NotBlankPage,
      CardPart,
      JsonIsEmpty,
      ParagraphIsEmpty
    },
    data() {
      return {
        icon: 'mdi-database',
        Item: 'AuditLog',
        failLink: '/audit-logs'
      }
    },
    methods: {
      fetchData() {
        return AuditLogs
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return '#' + Item.id
      },
      setSubtitle() {
        return 'Audit log'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Audit logy',
            to: '/audit-logs',
            exact: true
          }
        ]
      }
    }
  }
</script>
