<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :saveData="profileSetStatusVolunteerLockedData"
      @onSave="onProfileSetStatusVolunteerLocked"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-textarea
            v-model="ItemForm.note"
            :error-messages="errors.note"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Prosím uveďte dôvod zrušenia Vašej registrácie"
            :rows="2"
        />
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Profile from '@/services/profile'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'ProfileSetStatusVolunteerLocked',
    emits: [
      'onProfileSetStatusVolunteerLocked'
    ],
    components: {
      SaveModal
    },
    data() {
      return {
        color: 'error',
        icon: 'mdi-cancel',
        maxWidth: 500,
        buttonYes: 'Zrušiť registráciu',
        itemKey: 'User'
      }
    },
    methods: {
      fetchData() {
        return Profile
          .exist()
      },
      setTitle() {
        return 'Zrušiť registráciu dobrovoľníka'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setItemForm() {
        return {
          note: null
        }
      },
      profileSetStatusVolunteerLockedData(ItemForm) {
        return Profile
          .setStatusVolunteerLocked({
            data: ItemForm
          })
      },
      onProfileSetStatusVolunteerLocked(response) {
        this.$emit('onProfileSetStatusVolunteerLocked', response)
      }
    }
  }
</script>
