<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :saveData="createMoreDateData"
      @onSave="onCreateMoreDate"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
          sm="4"
      >
        <v-text-field
            v-model="ItemForm.start"
            :error-messages="errors.start"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Začiatok"
            type="time"
        />
      </v-col>

      <v-col
          cols="12"
          sm="4"
      >
        <v-text-field
            v-model="ItemForm.interval"
            :error-messages="errors.interval"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Interval"
            type="number"
        >
          <template
              v-slot:append
          >
            minút
          </template>
        </v-text-field>
      </v-col>

      <v-col
          cols="12"
          sm="4"
      >
        <v-text-field
            v-model="ItemForm.end"
            :error-messages="errors.end"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Koniec"
            type="time"
        />
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Interviews from '@/services/interviews'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'CreateMoreDate',
    props: {
      date: {
        type: String,
        required: true
      }
    },
    emits: [
      'onCreateMoreDate'
    ],
    components: {
      SaveModal
    },
    data() {
      return {
        color: 'success',
        icon: 'mdi-dots-horizontal',
        maxWidth: 500,
        buttonYes: 'Vytvoriť viac'
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť viac pohovorov'
      },
      setSubtitle() {
        return this.$moment(this.date).format('DD.MM.YYYY')
      },
      setItemForm() {
        return {
          interval: 20,
          start: null,
          end: null
        }
      },
      createMoreDateData(ItemForm) {
        return Interviews
          .createMoreDate({
            date: this.date,
            data: ItemForm
          })
      },
      onCreateMoreDate(response) {
        this.$emit('onCreateMoreDate', response)
      }
    }
  }
</script>
