var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SaveModal',{attrs:{"color":_vm.color,"icon":_vm.icon,"maxWidth":_vm.maxWidth,"buttonYes":_vm.buttonYes,"setTitle":_vm.setTitle,"setAlert":_vm.setAlert,"setItemForm":_vm.setItemForm,"saveData":_vm.updateMassData},on:{"onSave":_vm.onUpdateMass},scopedSlots:_vm._u([{key:"default",fn:function({
        close
      }){return [_c('v-col',{attrs:{"cols":"12"}},[_c('a',{staticClass:"success--text",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.updateMassStatusConfirmed.$el.click()}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]),_vm._v(" Chcem schváliť dobrovoľníkov ")],1),_c('UpdateMassStatusConfirmed',{ref:"updateMassStatusConfirmed",staticClass:"d-none",attrs:{"ids":_vm.ids},on:{"onUpdateMassStatusConfirmed":(response) => {
            close()

            _vm.onUpdateMass(response)
          }}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }