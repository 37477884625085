var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"title":title,"hasFilter":_vm.hasFilter,"colspan":_vm.colspan,"empty":_vm.empty,"defaultFilters":_vm.defaultFilters,"defaultOrder":_vm.defaultOrder,"defaultBy":_vm.defaultBy,"defaultLimit":_vm.defaultLimit,"fetchData":_vm.fetchData,"itemsKey":_vm.itemsKey},scopedSlots:_vm._u([{key:"action",fn:function({
            init
          }){return [_c('Create',{on:{"onCreate":init}}),_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"default","href":_vm.$global.axiosUrl() + 'app/volunteers/export',"target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}},{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","xl":"3"}},[_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})],1)]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('AutocompleteRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.placePrimary,"outlined":true,"dense":true,"hide-details":"auto","label":"Primárne miesto","clearable":true,"indexGroupValue":"volunteer_place"},on:{"change":setFilter},model:{value:(params.Filters.placePrimary),callback:function ($$v) {_vm.$set(params.Filters, "placePrimary", $$v)},expression:"params.Filters.placePrimary"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('AutocompleteRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.placeSecondary,"outlined":true,"dense":true,"hide-details":"auto","label":"Sekundárne miesto","clearable":true,"indexGroupValue":"volunteer_place"},on:{"change":setFilter},model:{value:(params.Filters.placeSecondary),callback:function ($$v) {_vm.$set(params.Filters, "placeSecondary", $$v)},expression:"params.Filters.placeSecondary"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('AutocompleteRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.status,"outlined":true,"dense":true,"hide-details":"auto","label":"Stav","clearable":true,"indexGroupValue":"volunteer_status"},on:{"change":setFilter},model:{value:(params.Filters.status),callback:function ($$v) {_vm.$set(params.Filters, "status", $$v)},expression:"params.Filters.status"}})],1)]}},{key:"list-head",fn:function({
            items,
            order,
            by,
            init,
            setOrderBy
          }){return [_c('th',[_c('v-checkbox',{attrs:{"dense":true,"hide-details":"auto"},on:{"change":(value) => {
                _vm.ids = []

                if (value) {
                  items.forEach((_Item) => {
                    _vm.ids.push(_Item.id)
                  })
                }
              }},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1),_c('th',{staticClass:"text-left fixed",attrs:{"colspan":2}},[_c('OrderByList',{attrs:{"label":"Meno & Priezvisko","value":"usedat.name","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" E-mail ")]),_c('th',[_vm._v(" Telefón ")]),_c('th',[_vm._v(" Dátum narodenia ")]),_c('th',[_vm._v(" Primárne miesto ")]),_c('th',[_vm._v(" Sekundárne miesto ")]),_c('th',[_vm._v(" Stav ")]),_c('th',{staticClass:"text-right fixed"},[(_vm.ids.length > 0)?_c('UpdateMass',{attrs:{"ids":_vm.ids},on:{"onUpdateMass":init}}):_vm._e()],1)]}},{key:"list-body-standard",fn:function({
            items,
            Item,
            init
          }){return [_c('td',[_c('v-checkbox',{attrs:{"value":Item.id,"dense":true,"hide-details":"auto"},on:{"change":() => {
                _vm.all = _vm.ids.length === items.length
              }},model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=$$v},expression:"ids"}})],1),_c('td',{attrs:{"width":"80px"}},[_c('a',{attrs:{"href":_vm.$global.cdnUrl() + 'media/files/by-token/' + Item.photoToken,"target":"_blank"}},[_c('v-img',{attrs:{"src":_vm.$global.cdnUrl() + 'media/files/by-token/' + Item.photoToken + '/40/40',"alt":"Fotografia","max-width":"40px","max-height":"40px"}})],1)]),_c('td',{staticClass:"text-left fixed"},[_c('TruncateHelper',{attrs:{"title":Item.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.name)+" ")])],1),_c('td',[_c('a',{attrs:{"href":'mailto: ' + Item.email}},[_c('TruncateHelper',{attrs:{"title":Item.email,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.email)+" ")])],1)]),_c('td',[_c('a',{attrs:{"href":'tel: ' + Item.phone}},[_c('TruncateHelper',{attrs:{"title":Item.phone,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.phone)+" ")])],1)]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.birthDatePlain,'DD.MM.YYYY'))+" ")]),_c('td',[_vm._v(" "+_vm._s(Item.placePrimaryEnum)+" ")]),_c('td',[_c('SpanIsEmpty',{attrs:{"value":Item.placeSecondaryEnum}},[_vm._v(" "+_vm._s(Item.placeSecondaryEnum)+" ")])],1),_c('td',[_c('v-badge',{attrs:{"inline":true,"color":Item.statusColorEnum,"content":Item.statusEnum}}),(Item.note)?_c('v-tooltip',{attrs:{"bottom":true},scopedSlots:_vm._u([{key:"activator",fn:function({
                  on,
                  attrs
                }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(Item.note)+" ")]):_vm._e()],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/volunteers/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1),_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":init}}),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":init}}):_vm._e()],1)]}},{key:"pagination-count",fn:function({
            limit,
            setLimit
          }){return _vm._l(([10, 25, 50, 100]),function(_limit,i){return _c('LimitPagination',{key:i,attrs:{"label":_limit,"value":_limit,"limit":limit},on:{"setLimit":setLimit}})})}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }