<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :buttonNo="buttonNo"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :saveData="confirmData"
      @onSave="onConfirm"
  />
</template>

<script>
  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'Confirm',
    props: {
      color: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      maxWidth: {
        type: Number,
        default: 500
      },
      buttonYes: {
        type: String,
        default: 'Áno'
      },
      buttonNo: {
        type: String,
        default: 'Nie'
      },
      fetchData: {
        type: Function,
        default: null
      },
      itemKey: {
        type: String,
        default: null
      },
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setAlert: {
        type: Function,
        default: () => null
      },
      confirmData: {
        type: Function,
        required: true
      }
    },
    emits: [
      'onConfirm'
    ],
    components: {
      SaveModal
    },
    methods: {
      onConfirm(response) {
        this.$emit('onConfirm', response)
      }
    }
  }
</script>
