<template>
  <FileSave
      :setTitle="setTitle"
      :fileData="fileData"
      @onFile="onFile"
  />
</template>

<script>
  import Registration from '@/services/registration'

  import FileSave from '@/components/_Part/_Modal/_Save/File'

  export default {
    name: 'File',
    emits: [
      'onFile'
    ],
    components: {
      FileSave
    },
    methods: {
      setTitle() {
        return 'Fotografia'
      },
      fileData(ItemForm) {
        return Registration
          .uploadPhoto({
            data: ItemForm
          })
      },
      onFile(response) {
        this.$emit('onFile', response)
      }
    }
  }
</script>
