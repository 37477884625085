<template>
  <v-col
      v-if="pages !== 0"
      cols="12"
  >
    <v-row
        align="center"
    >
      <v-col
          cols="auto"
      >
        <slot
            name="count"
            :setLimit="setLimit"
        />
      </v-col>

      <v-spacer/>

      <v-col
          cols="auto"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            :loading="loading"
            :disabled="page === 1 || loading"
            color="primary"
            @click.stop.prevent="previousPage"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </v-col>

      <v-col
          cols="auto"
      >
        {{ page }} / {{ pages }}
      </v-col>

      <v-col
          cols="auto"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            :loading="loading"
            :disabled="page === pages || loading"
            color="primary"
            @click.stop.prevent="nextPage"
        >
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      loading: {
        type: Boolean,
        required: true
      },
      total: {
        type: Number,
        required: true
      },
      limit: {
        type: Number,
        required: true
      },
      page: {
        type: Number,
        required: true
      }
    },
    emits: [
      'setParams'
    ],
    computed: {
      pages: function () {
        return Math.ceil(this.total / this.limit)
      }
    },
    methods: {
      setLimit(limit) {
        this.$emit('setParams', {
          limit: limit,
          page: 1
        })
      },
      previousPage() {
        this.$emit('setParams', {
          page: this.page - 1
        })
      },
      nextPage() {
        this.$emit('setParams', {
          page: this.page + 1
        })
      }
    }
  }
</script>
