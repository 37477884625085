import Vue from 'vue'

import Qs from 'qs'

class AuditLogs {
  async search({params}) {
    return await Vue.axios
      .get('app/audit-logs/search', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/audit-logs/' + id)
  }
}

const auditLogs = new AuditLogs()

export default auditLogs
