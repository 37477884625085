var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SaveModal',{attrs:{"color":_vm.color,"icon":_vm.icon,"maxWidth":_vm.maxWidth,"buttonYes":_vm.buttonYes,"buttonNo":_vm.buttonNo,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setAlert":_vm.setAlert,"setItemForm":_vm.setItemForm,"saveData":_vm.fileData},on:{"onSave":_vm.onFile},scopedSlots:_vm._u([{key:"default",fn:function({
        errors,
        ItemForm
      }){return [(errors.name || errors.type || errors.content || errors.size)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Pri nahrávaní súboru nastala chyba: "),(errors.name)?[_c('br'),_vm._v(" - "+_vm._s(errors.name.join(', '))+" ")]:_vm._e(),(errors.type)?[_c('br'),_vm._v(" - "+_vm._s(errors.type.join(', '))+" ")]:_vm._e(),(errors.content)?[_c('br'),_vm._v(" - "+_vm._s(errors.content.join(', '))+" ")]:_vm._e(),(errors.size)?[_c('br'),_vm._v(" - "+_vm._s(errors.size.join(', '))+" ")]:_vm._e()],2)],1):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"area"},[_c('vue-file-upload-as-bsf',{attrs:{"id":"file","label_input":"📎","max_file_size":64},on:{"fileLoaded":(files) => {
              const file = files[0]

              let FileForm = _vm.loadFile(file)

              if (FileForm) {
                ItemForm.name = FileForm.name

                ItemForm.type = FileForm.type

                ItemForm.content = FileForm.content

                ItemForm.size = FileForm.size
              }
            }}})],1)]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" (pre pridanie súboru stlačte na ikonu) ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }