<template>
  <FileSave
      :setTitle="setTitle"
      :fileData="fileData"
      @onFile="onFile"
  />
</template>

<script>
  import Courses from '@/services/courses'

  import FileSave from '@/components/_Part/_Modal/_Save/File'

  export default {
    name: 'File',
    props: {
      id: {
        type: Number,
        default: null
      }
    },
    emits: [
      'onFile'
    ],
    components: {
      FileSave
    },
    methods: {
      setTitle() {
        return 'Súbor'
      },
      fileData(ItemForm) {
        return Courses
          .uploadMaterial({
            id: this.id,
            data: ItemForm
          })
      },
      onFile(response) {
        this.$emit('onFile', response)
      }
    }
  }
</script>
