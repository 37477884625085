var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotBlankPage',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"fetchData":_vm.fetchData,"itemKey":_vm.Item,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs,"failLink":_vm.failLink},scopedSlots:_vm._u([{key:"action",fn:function({
        Item,
        reload
      }){return [_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":reload}}),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":() => {
          _vm.$router.push('/courses')
        }}}):_vm._e()]}},{key:"default",fn:function({
        calls,
        Item,
        reload
      }){return [_c('v-row',[(Item.status === 'deactivated')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Školenie je deaktivované. ")])],1):_vm._e(),(Item.content)?_c('v-col',{attrs:{"cols":"12"}},[_c('CardPart',{staticClass:"grey lighten-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(Item.content)}})]},proxy:true}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('CardPart',{staticClass:"grey lighten-5",attrs:{"icon":"mdi-attachment","title":'Materiály (' + Item.materials.length + ')'},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ColumnsIsEmpty',{attrs:{"value":Item.materials.length > 0}},_vm._l((Item.materials),function(_Material){return _c('v-col',{key:_Material.id,attrs:{"cols":"auto","align":"center"}},[_c('p',[_c('a',{attrs:{"href":_vm.$global.cdnUrl() + 'media/files/by-token/' + _Material.token,"target":"_blank"}},[_c('v-icon',{attrs:{"large":true}},[_vm._v(" mdi-file ")]),_c('br'),_vm._v(" "+_vm._s(_Material.name)+" ")],1)])])}),1)]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Quizzes',{key:calls,attrs:{"Course":Item},on:{"reload":reload}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }