<template>
  <AutomcompleteSelect
      :fetchData="fetchData"
      itemsKey="indexes"
      v-bind="{
        ...$attrs,
        itemValue: 'value',
        itemText: 'title'
      }"
      v-on="$listeners"
  />
</template>

<script>
  import Register from '@/services/register'

  import AutomcompleteSelect from '@/components/_Part/_Field/_Select/Autocomplete'

  export default {
    name: 'AutocompleteIndexesIndexGroupValue',
    props: {
      indexGroupValue: {
        type: String,
        required: true
      }
    },
    components: {
      AutomcompleteSelect
    },
    methods: {
      fetchData() {
        return Register
          .indexesIndexGroupValue({
            indexGroupValue: this.indexGroupValue
          })
      }
    }
  }
</script>
