<template>
  <ChartPart
      :title="title"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setOptions="setOptions"
  />
</template>

<script>
  import Statistics from '@/services/statistics'

  import ChartPart from '@/components/_Part/Chart'

  export default {
    name: 'StatisticsVolunteersByStatuses',
    components: {
      ChartPart
    },
    data() {
      return {
        title: 'Počet dobrovoľníkov podľa stavu',
        itemKey: 'indexes'
      }
    },
    methods: {
      fetchData() {
        return Statistics
          .volunteersByStatuses()
      },
      setOptions(items) {
        return {
          chart: {
            type: 'column'
          },
          credits: {
            enabled: false
          },
          title: {
            text: null
          },
          subtitle: {
            text: null
          },
          xAxis: {
            type: 'category',
            title: {
              text: 'Miesto'
            }
          },
          yAxis: {
            min: 0,
            tickInterval: 1,
            title: {
              text: 'Počet dobrovoľníkov'
            },
            stackLabels: {
              enabled: true
            }
          },
          tooltip: {
            headerFormat: '{series.name}<br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: {point.y}x<br>'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true
              }
            }
          },
          series: [
            {
              name: 'Celkovo',
              data: items.map((Item) => ({
                name: Item.title,
                y: Item.volunteersTotal
              }))
            }
          ]
        }
      }
    }
  }
</script>
