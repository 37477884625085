<template>
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          :maxWidth="maxWidth"
          :icon="icon"
          :title="title"
          :hasFilter="hasFilter"
          :colspan="colspan"
          :empty="empty"
          :defaultFilters="defaultFilters"
          :defaultOrder="defaultOrder"
          :defaultBy="defaultBy"
          :defaultLimit="defaultLimit"
          :fetchData="fetchData"
          :itemsKey="itemsKey"
      >
        <template
            v-slot:action="{
              init
            }"
        >
          <Create
              @onCreate="init"
          />

          <v-btn
              :icon="true"
              :outlined="true"
              color="default"
              :href="$global.axiosUrl() + 'app/admins/export'"
              target="_blank"
          >
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </template>

        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-col
              cols="12"
              sm="6"
              md="9"
          >
            <v-text-field
                v-model="params.query"
                :error-messages="errors.query"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Vyhľadávanie"
                :clearable="true"
                @change="setSearch"
            />
          </v-col>
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-col
              cols="12"
              sm="6"
              md="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.status"
                :error-messages="errors.Filters?.status"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Stav"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="admin_status"
            />
          </v-col>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              setOrderBy
            }"
        >
          <th
              class="text-left fixed"
          >
            <OrderByList
                label="Meno & Priezvisko"
                value="usedat.name"
                :order="order"
                :by="by"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            E-mail
          </th>

          <th>
            Telefón
          </th>

          <th>
            Stav
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item,
              init
            }"
        >
          <td
              class="text-left fixed"
          >
            <TruncateHelper
                :title="Item.name"
                maxWidth="300px"
            >
              {{ Item.name }}
            </TruncateHelper>
          </td>

          <td>
            <a
                :href="'mailto: ' + Item.email"
            >
              <TruncateHelper
                  :title="Item.email"
                  maxWidth="300px"
              >
                {{ Item.email }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            <a
                :href="'tel: ' + Item.phone"
            >
              <TruncateHelper
                  :title="Item.phone"
                  maxWidth="300px"
              >
                {{ Item.phone }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            <v-badge
                :inline="true"
                :color="Item.statusColorEnum"
                :content="Item.statusEnum"
            />

            <v-tooltip
                v-if="Item.note"
                :bottom="true"
            >
              <template
                  v-slot:activator="{
                    on,
                    attrs
                  }"
              >
                <span
                    v-on="on"
                    v-bind="attrs"
                >
                  <v-icon>
                    mdi-information
                  </v-icon>
                </span>
              </template>

              {{ Item.note }}
            </v-tooltip>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/admins/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>

            <template
                v-if="Item.id !== $store.state.auth.User.id"
            >
              <Update
                  :id="Item.id"
                  @onUpdate="init"
              />

              <Delete
                  v-if="Item.deletable"
                  :id="Item.id"
                  @onDelete="init"
              />
            </template>
          </td>
        </template>

        <template
            v-slot:pagination-count="{
              limit,
              setLimit
            }"
        >
          <LimitPagination
              v-for="(_limit, i) in [10, 25, 50, 100]"
              :key="i"
              :label="_limit"
              :value="_limit"
              :limit="limit"
              @setLimit="setLimit"
          />
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Admins from '@/services/admins'

  import Create from '@/components/Admins/Create'
  import Update from '@/components/Admins/Update'
  import Delete from '@/components/Admins/Delete'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Admins',
    components: {
      Create,
      Update,
      Delete,
      BlankPage,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 'calc(100% - 124px)',
        icon: 'mdi-account',
        hasFilter: true,
        colspan: 5,
        empty: 'Nenašli sa žiadni administrátori.',
        itemsKey: 'admins',
        defaultFilters: {
          status: null
        },
        defaultOrder: 'usedat.name',
        defaultBy: 'ASC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return Admins
          .search({
            params: params
          })
      },
      setTitle() {
        return 'Administrátori'
      }
    }
  }
</script>
