<template>
  <CreateSave
      :maxWidth="maxWidth"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createCourseData"
      @onCreate="onCreateCourse"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
          sm="8"
      >
        <v-text-field
            v-model="ItemForm.title"
            :error-messages="errors.title"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Názov"
        />
      </v-col>

      <v-col
          cols="12"
          sm="4"
      >
        <v-text-field
            v-model="ItemForm.minRate"
            :error-messages="errors.minRate"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Minimálne hodnotenie"
            type="number"
        >
          <template
              v-slot:append
          >
            %
          </template>
        </v-text-field>
      </v-col>

      <v-col
          cols="12"
      >
        <vue-editor
            v-model="ItemForm.content"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <Build
            :questions="ItemForm.questions"
            :correctQuestions="ItemForm.correctQuestions"
            :defaultQuestions="ItemForm.defaultQuestions"
        />
      </v-col>
    </template>
  </CreateSave>
</template>

<script>
  import Quizzes from '@/services/quizzes'

  import Build from '@/components/Quizzes/Build'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  export default {
    name: 'CreateCourse',
    props: {
      Course: {
        type: Object,
        required: true
      }
    },
    emits: [
      'onCreateCourse'
    ],
    components: {
      Build,
      CreateSave
    },
    data() {
      return {
        maxWidth: 750
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť test'
      },
      setSubtitle() {
        return this.Course.title
      },
      setItemForm() {
        return {
          title: null,
          minRate: null,
          content: null,
          questions: [],
          correctQuestions: [],
          defaultQuestions: []
        }
      },
      createCourseData(ItemForm) {
        return Quizzes
          .createCourse({
            courseId: this.Course.id,
            data: ItemForm
          })
      },
      onCreateCourse(response) {
        this.$emit('onCreateCourse', response)
      }
    }
  }
</script>
