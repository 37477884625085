<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <Form
          :User="Item"
      />
    </template>
  </NotBlankPage>
</template>

<script>
  import NewPassword from '@/services/new-password'

  import Form from '@/components/NewPasswordSet/Form'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'NewPasswordSet',
    components: {
      Form,
      NotBlankPage
    },
    data() {
      return {
        icon: 'mdi-cog',
        Item: 'User',
        failLink: '/new-password/restore'
      }
    },
    methods: {
      fetchData() {
        return NewPassword
          .exist({
            newPasswordToken: this.$route.params.newPasswordToken
          })
      },
      setTitle() {
        return 'Nastaviť nové heslo'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Vyžiadať nové heslo',
            to: '/new-password/restore',
            exact: true
          }
        ]
      }
    }
  }
</script>
