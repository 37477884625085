<template>
  <DeleteConfirm
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :deleteData="deleteData"
      @onDelete="onDelete"
  />
</template>

<script>
  import Admins from '@/services/admins'

  import DeleteConfirm from '@/components/_Part/_Modal/_Save/_Confirm/Delete'

  export default {
    name: 'Delete',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onDelete'
    ],
    components: {
      DeleteConfirm
    },
    data() {
      return {
        itemKey: 'Admin'
      }
    },
    methods: {
      fetchData() {
        return Admins
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Odstrániť administrátora'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setAlert() {
        return 'Naozaj chcete odstrániť administrátora?'
      },
      deleteData() {
        return Admins
          .delete({
            id: this.id
          })
      },
      onDelete(response) {
        this.$emit('onDelete', response)
      }
    }
  }
</script>
