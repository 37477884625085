var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CreateSave',{attrs:{"maxWidth":_vm.maxWidth,"setTitle":_vm.setTitle,"setItemForm":_vm.setItemForm,"createData":_vm.createData},on:{"onCreate":_vm.onCreate},scopedSlots:_vm._u([{key:"default",fn:function({
        errors,
        ItemForm
      }){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":errors.title,"filled":true,"dense":true,"hide-details":"auto","label":"Názov"},model:{value:(ItemForm.title),callback:function ($$v) {_vm.$set(ItemForm, "title", $$v)},expression:"ItemForm.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('vue-editor',{model:{value:(ItemForm.content),callback:function ($$v) {_vm.$set(ItemForm, "content", $$v)},expression:"ItemForm.content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-input',{attrs:{"value":_vm.materials,"error-messages":errors.materials,"filled":true,"dense":true,"hide-details":"auto","label":_vm.materials.length === 0
              ? 'Materiály'
              : null},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("   "),_c('v-row',[(_vm.materials.length > 0)?_vm._l((_vm.materials),function(_Material,index){return _c('v-col',{key:_Material.id,attrs:{"cols":"12"}},[_c('a',{attrs:{"href":_vm.$global.cdnUrl() + 'media/files/by-token/'  + _Material.token,"target":"_blank"}},[_vm._v(" "+_vm._s(_Material.name)+" ")]),_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"error"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {
                      ItemForm.materials.splice(index, 1)

                      _vm.materials.splice(index, 1)
                    }).apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('File',{on:{"onFile":(response) => {
                    ItemForm.materials.push(response.data.data.id)

                    _vm.materials.push({
                      id: response.data.data.id,
                      token: response.data.data.token,
                      name: response.data.data.name
                    })
                  }}})],1)],2)]},proxy:true}],null,true)})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }