<template>
  <UpdateSave
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="onUpdate"
  >
    <template
        v-slot:default="{
          Item,
          close,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="ItemForm.type"
            :error-messages="errors.type"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Typ"
            indexGroupValue="admin_type"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Osobné údaje"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.username"
                    :error-messages="errors.username"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Prezývka"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.gender"
                    :error-messages="errors.gender"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Pohlavie"
                    indexGroupValue="admin_gender"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.firstName"
                    :error-messages="errors.firstName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Meno"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.lastName"
                    :error-messages="errors.lastName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Priezvisko"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.email"
                    :error-messages="errors.email"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="E-mail"
                    type="email"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.phone"
                    :error-messages="errors.phone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                    :persistent-hint="true"
                    hint="Uvádzajte v tvare 090XXXXXXX"
                />
              </v-col>
            </v-row>
          </template>
        </CardPart>
      </v-col>

      <v-col
          cols="12"
      >
        <template
            v-if="Item.status !== 'canceled'"
        >
          <a
              class="error--text"
              @click.stop.prevent="$refs.updateStatusCanceled.$el.click()"
          >
            <v-icon
                color="error"
            >
              mdi-cancel
            </v-icon>

            Chcem zrušiť administrátora
          </a>

          <UpdateStatusCanceled
              ref="updateStatusCanceled"
              :id="Item.id"
              class="d-none"
              @onUpdateStatusCanceled="(response) => {
                close()

                onUpdate(response)
              }"
          />
        </template>

        <template
            v-if="Item.status === 'canceled'"
        >
          <a
              class="success--text"
              @click.stop.prevent="$refs.updateStatusRestored.$el.click()"
          >
            <v-icon
                color="success"
            >
              mdi-restore
            </v-icon>

            Chcem obnoviť administrátora
          </a>

          <UpdateStatusRestored
              ref="updateStatusRestored"
              :id="Item.id"
              class="d-none"
              @onUpdateStatusRestored="(response) => {
                close()

                onUpdate(response)
              }"
          />
        </template>
      </v-col>
    </template>
  </UpdateSave>
</template>

<script>
  import Admins from '@/services/admins'

  import UpdateStatusCanceled from '@/components/Admins/UpdateStatusCanceled'
  import UpdateStatusRestored from '@/components/Admins/UpdateStatusRestored'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import CardPart from '@/components/_Part/Card'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Update',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      UpdateStatusCanceled,
      UpdateStatusRestored,
      UpdateSave,
      CardPart,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 750,
        itemKey: 'Admin'
      }
    },
    methods: {
      fetchData() {
        return Admins
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Upraviť administrátora'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setItemForm(Item) {
        return {
          type: Item.type,
          username: Item.username,
          gender: Item.gender,
          firstName: Item.firstName,
          lastName: Item.lastName,
          email: Item.email,
          phone: Item.phone
        }
      },
      updateData(ItemForm) {
        return Admins
          .update({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
