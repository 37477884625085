<template>
  <GridPart
      :maxWidth="maxWidth"
      :icon="icon"
      :title="title"
      :hasFilter="hasFilter"
      :colspan="colspan"
      :empty="empty"
      :defaultFilters="defaultFilters"
      :defaultOrder="defaultOrder"
      :defaultBy="defaultBy"
      :defaultLimit="defaultLimit"
      :fetchData="fetchData"
      :itemsKey="itemsKey"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <CreateCourse
          :courseId="Course.id"
          :Course="Course"
          @onCreateCourse="(response) => {
            init()

            $emit('reload', response)
          }"
      />
    </template>

    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-col
          cols="12"
          sm="6"
          md="9"
      >
        <v-text-field
            v-model="params.query"
            :error-messages="errors.query"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Vyhľadávanie"
            :clearable="true"
            @change="setSearch"
        />
      </v-col>
    </template>

    <template
        v-slot:filter="{
              params,
              errors,
              setFilter
            }"
    >
      <v-col
          cols="12"
          sm="6"
          md="3"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="params.Filters.status"
            :error-messages="errors.Filters?.status"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Stav"
            :clearable="true"
            @change="setFilter"
            indexGroupValue="quizz_status"
        />
      </v-col>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          setOrderBy
        }"
    >
      <th
          class="text-left fixed"
      >
        <OrderByList
            label="Názov"
            value="quidat.title"
            :order="order"
            :by="by"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Minimálne hodnotenie
      </th>

      <th>
        Stav
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item,
          init
        }"
    >
      <td
          class="text-left fixed"
      >
        <TruncateHelper
            :title="Item.title"
            maxWidth="300px"
        >
          {{ Item.title }}
        </TruncateHelper>
      </td>

      <td>
        {{ Item.minRate|percent }}
      </td>

      <td>
        <v-badge
            :inline="true"
            :color="Item.statusColorEnum"
            :content="Item.statusEnum"
        />
      </td>

      <td
          class="text-right fixed"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="primary"
            :to="'/quizzes/' + Item.id"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>

        <Update
            :id="Item.id"
            @onUpdate="(response) => {
              init()

              $emit('reload', response)
            }"
        />

        <Delete
            v-if="Item.deletable"
            :id="Item.id"
            @onDelete="(response) => {
              init()

              $emit('reload', response)
            }"
        />
      </td>
    </template>

    <template
        v-slot:pagination-count="{
          limit,
          setLimit
        }"
    >
      <LimitPagination
          v-for="(_limit, i) in [10, 25, 50, 100]"
          :key="i"
          :label="_limit"
          :value="_limit"
          :limit="limit"
          @setLimit="setLimit"
      />
    </template>
  </GridPart>
</template>

<script>
  import Quizzes from '@/services/quizzes'

  import CreateCourse from '@/components/Quizzes/CreateCourse'
  import Update from '@/components/Quizzes/Update'
  import Delete from '@/components/Quizzes/Delete'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Quizzes',
    props: {
      Course: {
        type: Object,
        required: true
      }
    },
    emits: [
      'reload'
    ],
    components: {
      CreateCourse,
      Update,
      Delete,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 'calc(100% - 124px)',
        icon: 'mdi-help',
        title: 'Testy',
        hasFilter: true,
        colspan: 3,
        empty: 'Nenašli sa žiadne testy.',
        itemsKey: 'quizzes',
        defaultFilters: {
          status: null
        },
        defaultOrder: 'quidat.title',
        defaultBy: 'ASC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return Quizzes
          .searchCourse({
            courseId: this.Course.id,
            params: params
          })
      }
    }
  }
</script>
