import Vue from 'vue'

import Qs from 'qs'

class IndexGroups {
  async index() {
    return await Vue.axios
      .get('app/index-groups')
  }

  async search({params}) {
    return await Vue.axios
      .get('app/index-groups/search', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/index-groups', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/index-groups/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/index-groups/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/index-groups/' + id)
  }
}

const indexGroups = new IndexGroups()

export default indexGroups
