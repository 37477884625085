var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UpdateSave',{attrs:{"maxWidth":_vm.maxWidth,"fetchData":_vm.fetchData,"itemKey":_vm.itemKey,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setItemForm":_vm.setItemForm,"updateData":_vm.updateData},on:{"onUpdate":_vm.onUpdate},scopedSlots:_vm._u([{key:"default",fn:function({
        Item,
        close,
        errors,
        ItemForm
      }){return [_c('v-col',{attrs:{"cols":"12"}},[_c('AutocompleteCourses',{attrs:{"error-messages":errors.Course,"filled":true,"dense":true,"hide-details":"auto","label":"Školenie"},model:{value:(ItemForm.Course),callback:function ($$v) {_vm.$set(ItemForm, "Course", $$v)},expression:"ItemForm.Course"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"error-messages":errors.title,"filled":true,"dense":true,"hide-details":"auto","label":"Názov"},model:{value:(ItemForm.title),callback:function ($$v) {_vm.$set(ItemForm, "title", $$v)},expression:"ItemForm.title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"error-messages":errors.minRate,"filled":true,"dense":true,"hide-details":"auto","label":"Minimálne hodnotenie","type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" % ")]},proxy:true}],null,true),model:{value:(ItemForm.minRate),callback:function ($$v) {_vm.$set(ItemForm, "minRate", $$v)},expression:"ItemForm.minRate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('vue-editor',{model:{value:(ItemForm.content),callback:function ($$v) {_vm.$set(ItemForm, "content", $$v)},expression:"ItemForm.content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Build',{attrs:{"questions":ItemForm.questions,"correctQuestions":ItemForm.correctQuestions,"defaultQuestions":ItemForm.defaultQuestions}})],1),_c('v-col',{attrs:{"cols":"12"}},[(Item.status !== 'deactivated')?[_c('a',{staticClass:"error--text",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.updateStatusDeactivated.$el.click()}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-toggle-switch-off ")]),_vm._v(" Chcem deaktivovať test ")],1),_c('UpdateStatusDeactivated',{ref:"updateStatusDeactivated",staticClass:"d-none",attrs:{"id":Item.id},on:{"onUpdateStatusDeactivated":(response) => {
              close()

              _vm.onUpdate(response)
            }}})]:[_c('a',{staticClass:"success--text",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.updateStatusActivated.$el.click()}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-toggle-switch ")]),_vm._v(" Chcem aktivovať test ")],1),_c('UpdateStatusActivated',{ref:"updateStatusActivated",staticClass:"d-none",attrs:{"id":Item.id},on:{"onUpdateStatusActivated":(response) => {
              close()

              _vm.onUpdate(response)
            }}})]],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }