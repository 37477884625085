<template>
  <PagePart
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
      @onSuccess="onSuccess"
      @onFail="onFail"
  >
    <template
        v-slot:default="{
          loading,
          calls,
          Item,
          title,
          subtitle,
          backLink,
          refresh,
          reload
        }"
    >
      <CardPart
          :maxWidth="maxWidth"
          :icon="icon"
          :title="title"
          :subtitle="subtitle"
      >
        <template
            v-slot:action
        >
          <v-spacer/>

          <slot
              name="action"
              :calls="calls"
              :Item="Item"
              :refresh="refresh"
              :reload="reload"
          />

          <v-btn
              v-if="buttonHistory"
              :icon="true"
              :outlined="true"
              color="primary"
              :to="backLink"
              :exact="true"
          >
            <v-icon>
              mdi-history
            </v-icon>
          </v-btn>

          <v-btn
              v-if="buttonRefresh"
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="default"
              @click.stop.prevent="refresh"
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <template
            v-slot:default
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <slot
              name="default"
              :calls="calls"
              :Item="Item"
              :refresh="refresh"
              :reload="reload"
          />
        </template>
      </CardPart>
    </template>
  </PagePart>
</template>

<script>
  import PagePart from '@/components/_Part/Page'
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'NotBlank',
    props: {
      maxWidth: {
        type: String,
        default: 'calc(100% - 80px)'
      },
      icon: {
        type: String,
        default: null
      },
      buttonHistory: {
        type: Boolean,
        default: true
      },
      buttonRefresh: {
        type: Boolean,
        default: true
      },
      fetchData: {
        type: Function,
        required: true
      },
      itemKey: {
        type: String,
        required: true
      },
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setBreadcrumbs: {
        type: Function,
        default: () => []
      },
      failLink: {
        type: String,
        required: true
      }
    },
    emits: [
      'onSuccess',
      'onFail'
    ],
    components: {
      PagePart,
      CardPart
    },
    methods: {
      onSuccess(response) {
        this.$emit('onSuccess', response)
      },
      onFail(response) {
        this.$emit('onFail', response)

        this.$router.push(this.failLink)
      }
    }
  }
</script>
