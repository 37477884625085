<template>
  <CreateSave
      :maxWidth="maxWidth"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="onCreate"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.title"
            :error-messages="errors.title"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Názov"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <vue-editor
            v-model="ItemForm.content"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-input
            :value="materials"
            :error-messages="errors.materials"
            :filled="true"
            :dense="true"
            hide-details="auto"
            :label="
              materials.length === 0
                ? 'Materiály'
                : null
            "
        >
          <template
              v-slot:default
          >
            &nbsp;

            <v-row>
              <template
                  v-if="materials.length > 0"
              >
                <v-col
                    v-for="(_Material, index) in materials"
                    :key="_Material.id"
                    cols="12"
                >
                  <a
                      :href="$global.cdnUrl() + 'media/files/by-token/'  + _Material.token"
                      target="_blank"
                  >
                    {{ _Material.name }}
                  </a>

                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="error"
                      @click.stop.prevent="() => {
                        ItemForm.materials.splice(index, 1)

                        materials.splice(index, 1)
                      }"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </template>

              <v-col
                  cols="12"
              >
                <File
                    @onFile="(response) => {
                      ItemForm.materials.push(response.data.data.id)

                      materials.push({
                        id: response.data.data.id,
                        token: response.data.data.token,
                        name: response.data.data.name
                      })
                    }"
                />
              </v-col>
            </v-row>
          </template>
        </v-input>
      </v-col>
    </template>
  </CreateSave>
</template>

<script>
  import Courses from '@/services/courses'

  import File from '@/components/Courses/File'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  export default {
    name: 'Create',
    emits: [
      'onCreate'
    ],
    components: {
      File,
      CreateSave
    },
    data() {
      return {
        maxWidth: 750,
        materials: []
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť školenie'
      },
      setItemForm() {
        this.materials = []

        return {
          title: null,
          content: null,
          materials: []
        }
      },
      createData(ItemForm) {
        return Courses
          .create({
            data: ItemForm
          })
      },
      onCreate(response) {
        this.$emit('onCreate', response)
      }
    }
  }
</script>
