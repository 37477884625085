<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          calls,
          Item
        }"
    >
      <v-row>
        <v-col
            v-if="Item.content"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <div
                  v-html="Item.content"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <Form
                  :key="calls"
                  :Quizz="Item"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <Attempts
              :key="calls"
              :Quizz="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import QuizzesAuth from '@/services/quizzes-auth'

  import Form from '@/components/QuizzAuth/Form'
  import Attempts from '@/components/QuizzAuth/Attempts'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'QuizzAuth',
    components: {
      Form,
      Attempts,
      NotBlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-help',
        Item: 'Quizz',
        failLink: '/dashboard'
      }
    },
    methods: {
      fetchData() {
        return QuizzesAuth
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setSubtitle() {
        return 'Test'
      },
      setBreadcrumbs(Item) {
        return [
          {
            text: 'Školenia',
            to: '/courses-auth',
            exact: true
          },
          {
            text: Item.courseTitle,
            to: '/courses-auth/' + Item.courseId,
            exact: true
          }
        ]
      }
    }
  }
</script>
