<template>
  <v-form
      @submit.stop.prevent="submit"
  >
    <slot
        name="default"
        :loading="loading"
        :errors="errors"
        :alert="alert"
        :ItemForm="ItemForm"
        :submit="submit"
    />
  </v-form>
</template>

<script>
  export default {
    name: 'Form',
    props: {
      Item: {
        type: Object,
        default: null
      },
      setAlert: {
        type: Function,
        default: () => null
      },
      setItemForm: {
        type: Function,
        default: () => {}
      },
      submitData: {
        type: Function,
        required: true
      }
    },
    emits: [
      'onSuccess',
      'onErrors',
      'onFail'
    ],
    data() {
      return {
        loading: false,
        errors: {},
        alert: null,
        ItemForm: {}
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        this.errors = {}

        this.alert = this.setAlert(this.Item)

        this.ItemForm = this.setItemForm(this.Item)
      },
      submit() {
        this.loading = true

        this.errors = {}

        this.submitData(this.ItemForm)
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.$emit('onSuccess', response)
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.errors = error.response.data.errors

              this.$emit('onErrors', error.response)

              this.$nextTick(() => {
                if (document.querySelector('.v-messages.error--text')) {
                  document.querySelector('.v-messages.error--text').closest('.v-input').scrollIntoView()
                }
              })
            } else {
              this.$store.dispatch('layout/openSnackbar', {
                message: error.response.data.message
              })

              this.$emit('onFail', error.response)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
