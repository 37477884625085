import Vue from 'vue'

import Qs from 'qs'

class Attempts {
  async searchQuizz({quizzId, params}) {
    return await Vue.axios
      .get('app/attempts/search/by-quizz/' + quizzId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async searchVolunteer({volunteerId, params}) {
    return await Vue.axios
      .get('app/attempts/search/by-volunteer/' + volunteerId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/attempts/' + id)
  }
}

const attempts = new Attempts()

export default attempts
