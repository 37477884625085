var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalPart',{attrs:{"color":_vm.color,"icon":_vm.icon,"maxWidth":_vm.maxWidth,"fetchData":_vm.fetchData,"itemKey":_vm.itemKey,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle},scopedSlots:_vm._u([{key:"default",fn:function({
        Item,
        close
      }){return [_c('FormPart',{attrs:{"Item":Item,"setAlert":_vm.setAlert,"setItemForm":_vm.setItemForm,"submitData":_vm.saveData},on:{"onSuccess":(response) => {
          _vm.onSave(response)

          close()
        },"onFail":close},scopedSlots:_vm._u([{key:"default",fn:function({
            loading,
            errors,
            alert,
            ItemForm
          }){return [_c('v-row',[(alert)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(alert)+" ")])],1):_vm._e(),_vm._t("default",null,{"Item":Item,"close":close,"errors":errors,"ItemForm":ItemForm}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","rounded":true,"outlined":true,"loading":loading,"disabled":loading,"color":_vm.color}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_vm._v(" "+_vm._s(_vm.buttonYes)+" ")],1),_c('v-btn',{attrs:{"rounded":true,"outlined":true,"color":"default"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return close.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(_vm.buttonNo)+" ")],1)],1)],2)]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }