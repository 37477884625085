import Vue from 'vue'

import Qs from 'qs'

class AttemptsAuth {
  async searchQuizz({quizzId, params}) {
    return await Vue.axios
      .get('app/attempts/auth/search/by-quizz/' + quizzId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createQuizz({quizzId, data}) {
    return await Vue.axios
      .post('app/attempts/auth/by-quizz/' + quizzId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/attempts/auth/' + id)
  }
}

const attemptsAuth = new AttemptsAuth()

export default attemptsAuth
