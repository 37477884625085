<template>
  <v-app>
    <router-view/>

    <v-snackbar
        v-if="$store.state.layout.snackbar.status"
        v-model="$store.state.layout.snackbar.status"
        :app="true"
        :color="$store.state.layout.snackbar.message?.color"
    >
      {{ $store.state.layout.snackbar.message?.content }}
    </v-snackbar>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    metaInfo() {
      return {
        titleTemplate: this.$global.title() + ' - Systém | %s',
        meta: [
          {
            charset: 'utf-8'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          }
        ],
        link: [
          {
            type: 'text/css',
            href: this.$global.style(),
            rel: 'stylesheet'
          }
        ]
      }
    }
  }
</script>
