<template>
  <GridPart
      :icon="icon"
      :title="title"
      :colspan="colspan"
      :empty="empty"
      :defaultOrder="defaultOrder"
      :defaultBy="defaultBy"
      :defaultLimit="defaultLimit"
      :fetchData="fetchData"
      :itemsKey="itemsKey"
  >
    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-col
          cols="12"
      >
        <v-text-field
            v-model="params.query"
            :error-messages="errors.query"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Vyhľadávanie"
            :clearable="true"
            @change="setSearch"
        />
      </v-col>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          setOrderBy
        }"
    >
      <th>
        <OrderByList
            label="Dátum vykonania"
            value="quivoldat.createdAt"
            :order="order"
            :by="by"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Školenie
      </th>

      <th
          class="text-left fixed"
      >
        Test
      </th>

      <th>
        Minimálne hodnotenie
      </th>

      <th>
        Výsledné hodnotenie
      </th>

      <th>
        Výsledok
      </th>

      <th
          class="text-right fixed"
      />
    </template>

    <template
        v-slot:list-body-standard="{
          Item
        }"
    >
      <td>
        {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
      </td>

      <td>
        <router-link
            :to="'/courses/' + Item.courseId"
        >
          <TruncateHelper
              :title="Item.courseTitle"
              maxWidth="300px"
          >
            {{ Item.courseTitle }}
          </TruncateHelper>
        </router-link>
      </td>

      <td
          class="text-left fixed"
      >
        <router-link
            :to="'/quizzes/' + Item.quizzId"
        >
          <TruncateHelper
              :title="Item.quizzTitle"
              maxWidth="300px"
          >
            {{ Item.quizzTitle }}
          </TruncateHelper>
        </router-link>
      </td>

      <td>
        {{ Item.minRate|percent }}
      </td>

      <td>
        {{ Item.resultRate|percent }}
      </td>

      <td>
        <v-icon
            v-if="Item.resultSuccessful"
            color="success"
        >
          mdi-check
        </v-icon>

        <v-icon
            v-if="Item.resultUnsuccessful"
            color="error"
        >
          mdi-close
        </v-icon>
      </td>

      <td
          class="text-right fixed"
      >
        <v-btn
            :icon="true"
            :outlined="true"
            color="primary"
            :to="'/attempts/' + Item.id"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </td>
    </template>

    <template
        v-slot:pagination-count="{
          limit,
          setLimit
        }"
    >
      <LimitPagination
          v-for="(_limit, i) in [10, 25, 50, 100]"
          :key="i"
          :label="_limit"
          :value="_limit"
          :limit="limit"
          @setLimit="setLimit"
      />
    </template>
  </GridPart>
</template>

<script>
  import Attempts from '@/services/attempts'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  export default {
    name: 'Attempts',
    props: {
      Volunteer: {
        type: Object,
        required: true
      }
    },
    components: {
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper
    },
    data() {
      return {
        icon: 'mdi-ab-testing',
        title: 'Pokusy',
        colspan: 7,
        empty: 'Nenašli sa žiadne pokusy.',
        itemsKey: 'attempts',
        defaultOrder: 'attdat.createdAt',
        defaultBy: 'DESC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return Attempts
          .searchVolunteer({
            volunteerId: this.Volunteer.id,
            params: params
          })
      }
    }
  }
</script>
