<template>
  <GridPart
      :icon="icon"
      :title="title"
      :hasFilter="hasFilter"
      :colspan="colspan"
      :empty="empty"
      :defaultFilters="defaultFilters"
      :defaultOrder="defaultOrder"
      :defaultBy="defaultBy"
      :defaultLimit="defaultLimit"
      :fetchData="fetchData"
      :itemsKey="itemsKey"
  >
    <template
        v-slot:search="{
          params,
          errors,
          setSearch
        }"
    >
      <v-col
          cols="12"
          sm="6"
          md="8"
          xl="9"
      >
        <v-text-field
            v-model="params.query"
            :error-messages="errors.query"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Vyhľadávanie"
            :clearable="true"
            @change="setSearch"
        />
      </v-col>
    </template>

    <template
        v-slot:filter="{
          params,
          errors,
          setFilter
        }"
    >
      <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="params.Filters.status"
            :error-messages="errors.Filters?.status"
            :outlined="true"
            :dense="true"
            hide-details="auto"
            label="Stav"
            :clearable="true"
            @change="setFilter"
            indexGroupValue="volunteer_history_status"
        />
      </v-col>
    </template>

    <template
        v-slot:list-head="{
          order,
          by,
          setOrderBy
        }"
    >
      <th
          class="text-left fixed"
      >
        <OrderByList
            label="Dátum vytvorenia"
            value="usehisdat.createdAt"
            :order="order"
            :by="by"
            @setOrderBy="setOrderBy"
        />
      </th>

      <th>
        Stav
      </th>
    </template>

    <template
        v-slot:list-body-standard="{
          Item
        }"
    >
      <td
          class="text-left fixed"
      >
        {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
      </td>

      <td>
        <v-badge
            :inline="true"
            :color="Item.statusColorEnum"
            :content="Item.statusEnum"
        />

        <v-tooltip
            v-if="Item.note"
            :bottom="true"
        >
          <template
              v-slot:activator="{
                on,
                attrs
              }"
          >
            <span
                v-on="on"
                v-bind="attrs"
            >
              <v-icon>
                mdi-information
              </v-icon>
            </span>
          </template>

          {{ Item.note }}
        </v-tooltip>
      </td>
    </template>

    <template
        v-slot:pagination-count="{
          limit,
          setLimit
        }"
    >
      <LimitPagination
          v-for="(_limit, i) in [10, 25, 50, 100]"
          :key="i"
          :label="_limit"
          :value="_limit"
          :limit="limit"
          @setLimit="setLimit"
      />
    </template>
  </GridPart>
</template>

<script>
  import UserHistories from '@/services/user-histories'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'UserHistories',
    props: {
      User: {
        type: Object,
        required: true
      }
    },
    components: {
      GridPart,
      OrderByList,
      LimitPagination,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        icon: 'mdi-comment',
        title: 'História',
        hasFilter: true,
        colspan: 2,
        empty: 'Nenašla sa žiadna história.',
        itemsKey: 'userHistories',
        defaultFilters: {
          status: null,
        },
        defaultOrder: 'usehisdat.createdAt',
        defaultBy: 'DESC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return UserHistories
          .searchUser({
            userId: this.User.id,
            params: params
          })
      }
    }
  }
</script>
