import Vue from 'vue'

class InterviewsAuth {
  async indexStartAndEnd({start, end}) {
    return await Vue.axios
      .get('app/interviews/auth/by-start-and-end/' + start + '/' + end)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/interviews/auth/' + id)
  }

  async updateStatusFree({id, data}) {
    return await Vue.axios
      .put('app/interviews/auth/' + id + '/status/free', data)
  }

  async updateStatusReserved({id, data}) {
    return await Vue.axios
      .put('app/interviews/auth/' + id + '/status/reserved', data)
  }
}

const interviewsAuth = new InterviewsAuth()

export default interviewsAuth
