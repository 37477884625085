<template>
  <v-alert
      v-if="thanks"
      type="info"
  >
    Skontrolujte si e-mail. :)
  </v-alert>

  <FormPart
      v-else
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
  >
    <template
        v-slot:default="{
          loading,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.username"
              :error-messages="errors.username"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Prezývka"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-btn
              type="submit"
              :large="true"
              :block="true"
              :loading="loading"
              :disabled="loading"
              color="primary"
          >
            <v-icon>
              mdi-lock
            </v-icon>

            Vyžiadať nové heslo
          </v-btn>
        </v-col>

        <v-col
            cols="12"
            class="text-center"
        >
          <router-link
              to="/login"
          >
            Späť na prihlásenie
          </router-link>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import NewPassword from '@/services/new-password'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Form',
    components: {
      FormPart
    },
    data() {
      return {
        thanks: false
      }
    },
    methods: {
      setItemForm() {
        return {
          username: null
        }
      },
      submitData(ItemForm) {
        return NewPassword
          .restore({
            data: ItemForm
          })
      },
      onSuccess() {
        this.thanks = true
      }
    }
  }
</script>
