<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            v-if="Item.error"
            cols="12"
        >
          <v-alert
              type="error"
          >
            {{ Item.error }}
          </v-alert>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Priečinok
                  </h3>

                  <p>
                    {{ Item.dir }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Názov
                  </h3>

                  <p>
                    <a
                        :href="$global.cdnUrl() + 'media/files/by-token/' + Item.token"
                        target="_blank"
                    >
                      {{ Item.name }}
                    </a>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Typ
                  </h3>

                  <p>
                    {{ Item.type }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Stav
                  </h3>

                  <p>
                    <v-badge
                        :inline="true"
                        :color="Item.statusColorEnum"
                        :content="Item.statusEnum"
                    />
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Dátum a čas zadania
                  </h3>

                  <p>
                    {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <h3>
                    Dátum a čas nahratia
                  </h3>

                  <ParagraphIsEmpty
                      :value="Item.updatedAt"
                  >
                    {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
                  </ParagraphIsEmpty>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Files from '@/services/files'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  import ParagraphIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Paragraph'

  export default {
    name: 'File',
    components: {
      NotBlankPage,
      CardPart,
      ParagraphIsEmpty
    },
    data() {
      return {
        icon: 'mdi-file',
        Item: 'File',
        failLink: '/files'
      }
    },
    methods: {
      fetchData() {
        return Files
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return '#' + Item.id
      },
      setSubtitle() {
        return 'Súbor'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Súbory',
            to: '/files',
            exact: true
          }
        ]
      }
    }
  }
</script>
