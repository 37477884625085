<template>
  <UpdateSave
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="onUpdate"
  >
    <template
        v-slot:default="{
          Item,
          close,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <AutocompleteCourses
            v-model="ItemForm.Course"
            :error-messages="errors.Course"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Školenie"
        />
      </v-col>

      <v-col
          cols="12"
          sm="8"
      >
        <v-text-field
            v-model="ItemForm.title"
            :error-messages="errors.title"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Názov"
        />
      </v-col>

      <v-col
          cols="12"
          sm="4"
      >
        <v-text-field
            v-model="ItemForm.minRate"
            :error-messages="errors.minRate"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Minimálne hodnotenie"
            type="number"
        >
          <template
              v-slot:append
          >
            %
          </template>
        </v-text-field>
      </v-col>

      <v-col
          cols="12"
      >
        <vue-editor
            v-model="ItemForm.content"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <Build
            :questions="ItemForm.questions"
            :correctQuestions="ItemForm.correctQuestions"
            :defaultQuestions="ItemForm.defaultQuestions"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <template
            v-if="Item.status !== 'deactivated'"
        >
          <a
              class="error--text"
              @click.stop.prevent="$refs.updateStatusDeactivated.$el.click()"
          >
            <v-icon
                color="error"
            >
              mdi-toggle-switch-off
            </v-icon>

            Chcem deaktivovať test
          </a>

          <UpdateStatusDeactivated
              ref="updateStatusDeactivated"
              :id="Item.id"
              class="d-none"
              @onUpdateStatusDeactivated="(response) => {
                close()

                onUpdate(response)
              }"
          />
        </template>

        <template
            v-else
        >
          <a
              class="success--text"
              @click.stop.prevent="$refs.updateStatusActivated.$el.click()"
          >
            <v-icon
                color="success"
            >
              mdi-toggle-switch
            </v-icon>

            Chcem aktivovať test
          </a>

          <UpdateStatusActivated
              ref="updateStatusActivated"
              :id="Item.id"
              class="d-none"
              @onUpdateStatusActivated="(response) => {
                close()

                onUpdate(response)
              }"
          />
        </template>
      </v-col>
    </template>
  </UpdateSave>
</template>

<script>
  import Quizzes from '@/services/quizzes'

  import Build from '@/components/Quizzes/Build'
  import UpdateStatusDeactivated from '@/components/Quizzes/UpdateStatusDeactivated'
  import UpdateStatusActivated from '@/components/Quizzes/UpdateStatusActivated'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  import AutocompleteCourses from '@/components/Courses/Autocomplete'

  export default {
    name: 'Update',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      Build,
      UpdateStatusDeactivated,
      UpdateStatusActivated,
      UpdateSave,
      AutocompleteCourses
    },
    data() {
      return {
        maxWidth: 750,
        itemKey: 'Quizz'
      }
    },
    methods: {
      fetchData() {
        return Quizzes
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Upraviť test'
      },
      setSubtitle(Item) {
        return Item.courseTitle + ' | ' + Item.title
      },
      setItemForm(Item) {
        return {
          Course: Item.courseId,
          title: Item.title,
          minRate: Item.minRate,
          content: Item.content,
          questions: Item.questions,
          correctQuestions: Item.correctQuestions,
          defaultQuestions: Item.defaultQuestions
        }
      },
      updateData(ItemForm) {
        return Quizzes
          .update({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
