<template>
  <v-row>
    <v-col
        v-if="questions.length > 0"
        cols="12"
    >
      <v-row>
        <v-col
            v-for="(_question, i) in questions"
            :key="i"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="_question.title"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      :label="'Otázka č.' + (i + 1)"
                  >
                    <template
                        v-slot:append-outer
                    >
                      <v-btn
                          v-if="i > 0"
                          :small="true"
                          :icon="true"
                          :outlined="true"
                          color="primary"
                          @click.stop.prevent="goUpQuestion(i)"
                      >
                        <v-icon>
                          mdi-arrow-up
                        </v-icon>
                      </v-btn>

                      <v-btn
                          v-if="i < questions.length - 1"
                          :small="true"
                          :icon="true"
                          :outlined="true"
                          color="primary"
                          @click.stop.prevent="goDownQuestion(i)"
                      >
                        <v-icon>
                          mdi-arrow-down
                        </v-icon>
                      </v-btn>

                      <v-btn
                          :small="true"
                          :icon="true"
                          :outlined="true"
                          color="error"
                          @click.stop.prevent="deleteQuestion(i)"
                      >
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="_question.help"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      :label="'Nápoveda k otázke č.' + (i + 1)"
                  />
                </v-col>

                <v-col
                    v-if="_question.answers.length > 0"
                    cols="12"
                >
                  <v-row>
                    <v-col
                        v-for="(_answer, j) in _question.answers"
                        :key="j"
                        cols="12"
                    >
                      <v-row
                          align="center"
                      >
                        <v-col
                            cols="auto"
                        >
                          <v-checkbox
                              v-model="correctQuestions[i].answers[j]"
                              :true-value="1"
                              :false-value="0"
                              :dense="true"
                              hide-details="auto"
                              label="správne"
                          />
                        </v-col>

                        <v-col
                            cols="auto"
                        >
                          <v-checkbox
                              v-model="defaultQuestions[i].answers[j]"
                              :true-value="1"
                              :false-value="0"
                              :dense="true"
                              hide-details="auto"
                              label="východzie"
                          />
                        </v-col>

                        <v-col
                            cols="8"
                        >
                          <v-text-field
                              v-model="_answer.title"
                              :filled="true"
                              :dense="true"
                              hide-details="auto"
                              :label="'Odpoveď č.' + (j + 1)"
                          >
                            <template
                                v-slot:append-outer
                            >
                              <v-btn
                                  v-if="j > 0"
                                  :small="true"
                                  :icon="true"
                                  :outlined="true"
                                  color="primary"
                                  @click.stop.prevent="goUpAnswer(i, j)"
                              >
                                <v-icon>
                                  mdi-arrow-up
                                </v-icon>
                              </v-btn>

                              <v-btn
                                  v-if="j < questions[i].answers.length - 1"
                                  :small="true"
                                  :icon="true"
                                  :outlined="true"
                                  color="primary"
                                  @click.stop.prevent="goDownAnswer(i, j)"
                              >
                                <v-icon>
                                  mdi-arrow-down
                                </v-icon>
                              </v-btn>

                              <v-btn
                                  :small="true"
                                  :icon="true"
                                  :outlined="true"
                                  color="error"
                                  @click.stop.prevent="deleteAnswer(i, j)"
                              >
                                <v-icon>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                    cols="12"
                >
                  <v-btn
                      :small="true"
                      :rounded="true"
                      :outlined="true"
                      color="success"
                      @click.stop.prevent="addAnswer(i)"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>

                    odpoveď
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>
      </v-row>
    </v-col>

    <v-col
        cols="12"
    >
      <v-btn
          :small="true"
          :rounded="true"
          :outlined="true"
          color="success"
          @click.stop.prevent="addQuestion"
      >
        <v-icon>
          mdi-plus
        </v-icon>

        otázka
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Build',
    props: {
      questions: {
        type: Array,
        required: true
      },
      correctQuestions: {
        type: Array,
        required: true
      },
      defaultQuestions: {
        type: Array,
        required: true
      }
    },
    components: {
      CardPart
    },
    methods: {
      addQuestion() {
        this.questions.push({
          title: null,
          help: null,
          answers: []
        })

        this.correctQuestions.push({
          answers: []
        })

        this.defaultQuestions.push({
          answers: []
        })
      },
      goUpQuestion(i) {
        const current = this.questions[i]

        const currentCorrectQuestions = this.correctQuestions[i]

        const currentDefaultQuestions = this.defaultQuestions[i]

        this.questions.splice(i, 1)

        this.correctQuestions.splice(i, 1)

        this.defaultQuestions.splice(i, 1)

        this.questions.splice(i - 1, 0, current)

        this.correctQuestions.splice(i - 1, 0, currentCorrectQuestions)

        this.defaultQuestions.splice(i - 1, 0, currentDefaultQuestions)
      },
      goDownQuestion(i) {
        const current = this.questions[i]

        const currentCorrectQuestions = this.correctQuestions[i]

        const currentDefaultQuestions = this.defaultQuestions[i]

        this.questions.splice(i, 1)

        this.correctQuestions.splice(i, 1)

        this.defaultQuestions.splice(i, 1)

        this.questions.splice(i + 1, 0, current)

        this.correctQuestions.splice(i + 1, 0, currentCorrectQuestions)

        this.defaultQuestions.splice(i + 1, 0, currentDefaultQuestions)
      },
      deleteQuestion(i) {
        this.questions.splice(i, 1)

        this.correctQuestions.splice(i, 1)

        this.defaultQuestions.splice(i, 1)
      },
      addAnswer(i) {
        this.questions[i].answers.push({
          title: null
        })

        this.correctQuestions[i].answers.push(0)

        this.defaultQuestions[i].answers.push(0)
      },
      goUpAnswer(i, j) {
        const current = this.questions[i].answers[j]

        const currentCorrectQuestions = this.correctQuestions[i].answers[j]

        const currentDefaultQuestions = this.defaultQuestions[i].answers[j]

        this.questions[i].answers.splice(j, 1)

        this.correctQuestions[i].answers.splice(j, 1)

        this.defaultQuestions[i].answers.splice(j, 1)

        this.questions[i].answers.splice(j - 1, 0, current)

        this.correctQuestions[i].answers.splice(j - 1, 0, currentCorrectQuestions)

        this.defaultQuestions[i].answers.splice(j - 1, 0, currentDefaultQuestions)
      },
      goDownAnswer(i, j) {
        const current = this.questions[i].answers[j]

        const currentCorrectQuestions = this.correctQuestions[i].answers[j]

        const currentDefaultQuestions = this.defaultQuestions[i].answers[j]

        this.questions[i].answers.splice(j, 1)

        this.correctQuestions[i].answers.splice(j, 1)

        this.defaultQuestions[i].answers.splice(j, 1)

        this.questions[i].answers.splice(j + 1, 0, current)

        this.correctQuestions[i].answers.splice(j + 1, 0, currentCorrectQuestions)

        this.defaultQuestions[i].answers.splice(j + 1, 0, currentDefaultQuestions)
      },
      deleteAnswer(i, j) {
        this.questions[i].answers.splice(j, 1)

        this.correctQuestions[i].answers.splice(j, 1)

        this.defaultQuestions[i].answers.splice(j, 1)
      }
    }
  }
</script>
