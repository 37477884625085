import Vue from 'vue'

export const calculate = {
  eventMinor(birthDate) {
    if (!birthDate) {
      return null
    }

    return Vue.prototype.$moment('2025-06-11').subtract(16, 'years') >= Vue.prototype.$moment(birthDate) && Vue.prototype.$moment('2025-06-11').subtract(18, 'years') < Vue.prototype.$moment(birthDate)
  },
  eventCreate(date) {
    if (!date) {
      return null
    }

    return Vue.prototype.$moment(date) > Vue.prototype.$moment()
  }
}

Vue.prototype.$calculate = calculate
