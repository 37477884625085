<template>
  <div>
    <v-app-bar
        :app="true"
        :fixed="true"
        width="auto"
        height="72px"
        class="custom-app-bar custom-toolbar"
    >
      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          @click.stop.prevent="$store.dispatch('layout/toggleNavigationDrawer')"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>

      <v-spacer/>

      <router-link
          to="/"
      >
        <v-img
            :src="$global.logo()"
            alt="Logo"
            max-width="122px"
            max-height="48px"
        />
      </router-link>

      <v-spacer/>

      <LogoutConfirm/>
    </v-app-bar>

    <v-navigation-drawer
        v-if="$store.state.layout.navigationDrawer.status"
        v-model="$store.state.layout.navigationDrawer.status"
        :app="true"
        :fixed="true"
        width="325px"
        height="100%"
        class="custom-navigation-drawer"
    >
      <v-list
          class="custom-list-user"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.state.auth.User.name }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ $store.state.auth.User.typeEnum }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              <ProfileSetAdminSave
                  v-if="$store.state.auth.User.division === 'admin'"
              />

              <ProfileSetVolunteerSave
                  v-if="$store.state.auth.User.division === 'volunteer'"
              />

              <v-btn
                  :icon="true"
                  :outlined="true"
                  color="primary"
                  @click.stop.prevent="$store.dispatch('layout/toggleNavigationDrawer')"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list
          v-if="items"
          class="custom-list-menu"
      >
        <MenuApp
            v-for="(_item, i) in items"
            :key="i"
            :item="_item"
        />
      </v-list>
    </v-navigation-drawer>

    <v-main
        class="custom-main custom-main-backend"
    >
      <v-container
          :fluid="true"
      >
        <v-row>
          <v-col
              cols="12"
          >
            <v-breadcrumbs
                v-if="$store.state.layout.breadcrumbs.length > 0"
                divider="/"
                :items="$store.state.layout.breadcrumbs"
            />
          </v-col>
        </v-row>

        <router-view/>
      </v-container>
    </v-main>

    <v-footer
        :app="true"
        :fixed="true"
        width="100%"
        height="36px"
        class="custom-footer"
    >
      <a
          href="https://under21.futbalsfz.sk/"
          target="_blank"
      >
        <v-img
            :src="$global.copyright()"
            alt="Logo"
            max-width="28px"
            max-height="28px"
        />
      </a>

      <v-spacer/>

      <v-badge
          :inline="true"
          :content="'v'+ $global.version()"
      />
    </v-footer>
  </div>
</template>

<script>
  import menu from '@/plugins/config/menu'

  import LogoutConfirm from '@/components/_App/_Modal/_Save/_Confirm/Logout'

  import ProfileSetAdminSave from '@/components/_App/_Modal/_Save/ProfileSetAdmin'
  import ProfileSetVolunteerSave from '@/components/_App/_Modal/_Save/ProfileSetVolunteer'

  import MenuApp from '@/components/_App/Menu'

  export default {
    name: 'PageBackend',
    components: {
      LogoutConfirm,
      ProfileSetAdminSave,
      ProfileSetVolunteerSave,
      MenuApp
    },
    computed: {
      items: function () {
        return menu.items[this.$store.state.auth.User.type]
      }
    }
  }
</script>
