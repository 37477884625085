import Vue from 'vue'

class Register {
  async indexesIndexGroupValue({indexGroupValue}) {
    return await Vue.axios
      .get('register/indexes/by-index-group-value/' + indexGroupValue)
  }
}

const register = new Register()

export default register
