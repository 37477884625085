<template>
  <component
      :is="name"
      :items="items"
      :cache-items="true"
      no-data-text="Žiadne výsledky"
      :return-object="false"
      :loading="loading"
      :disabled="loading"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template
        v-slot:append-outer
    >
      <slot
          name="action"
          :init="init"
      />

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click.stop.prevent="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </template>
  </component>
</template>

<script>
  export default {
    name: 'Select',
    props: {
      name: {
        type: String,
        required: true
      },
      fetchData: {
        type: Function,
        required: true
      },
      itemsKey: {
        type: String,
        required: true
      }
    },
    emits: [
      'onSuccess',
      'onFail'
    ],
    data() {
      return {
        loading: false,
        total: 0,
        items: []
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.initData()
      },
      initData() {
        this.prepareData()
      },
      prepareData() {
        this.loading = true

        this.fetchData()
          .then((response) => {
            this.total = response.data.data.total

            this.items = response.data.data[this.itemsKey]

            this.$emit('onSuccess', response)
          })
          .catch((error) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: error.response.data.message
            })

            this.$emit('onFail', error.response)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
