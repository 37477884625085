const master = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/admins',
    title: 'Administrátori',
    icon: 'mdi-account'
  },
  {
    to: '/volunteers',
    title: 'Dobrovoľníci',
    icon: 'mdi-handshake'
  },
  {
    to: '/interviews',
    title: 'Pohovory',
    icon: 'mdi-calendar'
  },
  {
    to: '/courses',
    title: 'Školenia',
    icon: 'mdi-book'
  },
  {
    title: 'Nastavenia',
    icon: 'mdi-cog',
    value: false,
    subItems: [
      {
        to: '/index-groups',
        title: 'Skupiny číselníkov',
        icon: 'mdi-forum'
      },
      {
        to: '/audit-logs',
        title: 'Audit logy',
        icon: 'mdi-database'
      },
      {
        to: '/mails',
        title: 'Maily',
        icon: 'mdi-email'
      },
      {
        to: '/files',
        title: 'Súbory',
        icon: 'mdi-file'
      }
    ]
  }
]

const manager = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  }
]

const volunteer = [
  {
    to: '/dashboard',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/interviews-auth',
    title: 'Pohovory',
    icon: 'mdi-calendar'
  },
  {
    to: '/courses-auth',
    title: 'Školenia',
    icon: 'mdi-book'
  }
]

const items = {
  master,
  manager,
  volunteer
}

const menu = {
  items
}

export default menu
