<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :buttonNo="buttonNo"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :setItemForm="setItemForm"
      :saveData="fileData"
      @onSave="onFile"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          v-if="errors.name || errors.type || errors.content || errors.size"
          cols="12"
      >
        <v-alert
            type="error"
        >
          Pri nahrávaní súboru nastala chyba:

          <template
              v-if="errors.name"
          >
            <br/>

            - {{ errors.name.join(', ') }}
          </template>

          <template
              v-if="errors.type"
          >
            <br/>

            - {{ errors.type.join(', ') }}
          </template>

          <template
              v-if="errors.content"
          >
            <br/>

            - {{ errors.content.join(', ') }}
          </template>

          <template
              v-if="errors.size"
          >
            <br/>

            - {{ errors.size.join(', ') }}
          </template>
        </v-alert>
      </v-col>

      <v-col
          cols="12"
          class="text-center"
      >
        <div
            class="area"
        >
          <vue-file-upload-as-bsf
              id="file"
              label_input="📎"
              :max_file_size="64"
              @fileLoaded="(files) => {
                const file = files[0]

                let FileForm = loadFile(file)

                if (FileForm) {
                  ItemForm.name = FileForm.name

                  ItemForm.type = FileForm.type

                  ItemForm.content = FileForm.content

                  ItemForm.size = FileForm.size
                }
              }"
          />
        </div>
      </v-col>

      <v-col
          cols="12"
          class="text-center"
      >
        (pre pridanie súboru stlačte na ikonu)
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'File',
    props: {
      color: {
        type: String,
        default: 'success'
      },
      icon: {
        type: String,
        default: 'mdi-upload'
      },
      maxWidth: {
        type: Number,
        default: 500
      },
      buttonYes: {
        type: String,
        default: 'Nahrať'
      },
      buttonNo: {
        type: String,
        default: 'Zavrieť'
      },
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setAlert: {
        type: Function,
        default: () => null
      },
      fileData: {
        type: Function,
        required: true
      }
    },
    emits: [
      'onFile'
    ],
    components: {
      SaveModal
    },
    methods: {
      setItemForm() {
        return {
          name: null,
          type: null,
          content: null,
          size: null
        }
      },
      loadFile(file) {
        let FileForm = null

        if (file) {
          FileForm = {
            name: file.name,
            type: file.type,
            content: file.data,
            size: file.data.length
          }
        }

        return FileForm
      },
      onFile(response) {
        this.$emit('onFile', response)
      }
    }
  }
</script>
