<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                >
                  <h3>
                    Školenie
                  </h3>

                  <p>
                    <router-link
                        :to="'/courses/' + Item.courseId"
                    >
                      {{ Item.courseTitle }}
                    </router-link>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                >
                  <h3>
                    Test
                  </h3>

                  <p>
                    <router-link
                        :to="'/quizzes/' + Item.quizzId"
                    >
                      {{ Item.quizzTitle }}
                    </router-link>
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                >
                  <h3>
                    Výsledné hodnotenie
                  </h3>

                  <p>
                    {{ Item.resultRate|percent }}
                  </p>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                >
                  <h3>
                    Výsledok
                  </h3>

                  <p>
                    <v-icon
                        v-if="Item.resultSuccessful"
                        color="success"
                    >
                      mdi-check
                    </v-icon>

                    <v-icon
                        v-if="Item.resultUnsuccessful"
                        color="error"
                    >
                      mdi-close
                    </v-icon>
                  </p>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            v-if="Item.questions.length > 0"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    v-for="(_question, i) in Item.questions"
                    :key="i"
                    cols="12"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h2
                          v-if="_question.title"
                      >
                        {{ i + 1 }}. {{ _question.title }}
                      </h2>

                      <p
                          v-if="_question.help"
                      >
                        {{ _question.help }}
                      </p>
                    </v-col>

                    <v-col
                        v-if="_question.answers.length > 0"
                        cols="12"
                    >
                      <v-row>
                        <v-col
                            v-for="(_answer, j) in _question.answers"
                            :key="j"
                            cols="12"
                        >
                          <v-row
                              align="center"
                          >
                            <v-col
                                cols="auto"
                            >
                              <v-checkbox
                                  v-model="Item.checkQuestions[i].answers[j]"
                                  :true-value="1"
                                  :false-value="0"
                                  :dense="true"
                                  hide-details="auto"
                                  :readonly="true"
                                  :label="_answer.title"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import AttemptsAuth from '@/services/attempts-auth'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'AttemptAuth',
    components: {
      NotBlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-ab-testing',
        Item: 'Attempt',
        failLink: '/courses-auth'
      }
    },
    methods: {
      fetchData() {
        return AttemptsAuth
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return '#' + Item.id
      },
      setSubtitle() {
        return 'Pokus'
      },
      setBreadcrumbs(Item) {
        return [
          {
            text: 'Školenia',
            to: '/courses-auth',
            exact: true
          },
          {
            text: Item.courseTitle,
            to: '/courses-auth/' + Item.courseId,
            exact: true
          },
          {
            text: Item.quizzTitle,
            to: '/quizzes-auth/' + Item.quizzId,
            exact: true
          }
        ]
      }
    }
  }
</script>
