<template>
  <component
      v-if="value"
      :is="ifNo"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot/>
  </component>

  <component
      v-else
      :is="ifYes"
  >
    {{ empty }}
  </component>
</template>

<script>
  export default {
    name: 'IsEmpty',
    props: {
      value: {
        required: true
      },
      ifNo: {
        type: String,
        default: 'span'
      },
      ifYes: {
        type: String,
        default: 'span'
      },
      empty: {
        type: String,
        default: '-'
      }
    }
  }
</script>
