<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :saveData="updateStatusRestoredData"
      @onSave="onUpdateStatusRestored"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-textarea
            v-model="ItemForm.note"
            :error-messages="errors.note"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Prosím uveďte dôvod obnovenia administrátora"
            :rows="2"
        />
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Admins from '@/services/admins'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'UpdateStatusRestored',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdateStatusRestored'
    ],
    components: {
      SaveModal
    },
    data() {
      return {
        color: 'success',
        icon: 'mdi-restore',
        maxWidth: 500,
        buttonYes: 'Obnoviť',
        itemKey: 'Admin'
      }
    },
    methods: {
      fetchData() {
        return Admins
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Obnoviť administrátora'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setItemForm() {
        return {
          note: null
        }
      },
      updateStatusRestoredData(ItemForm) {
        return Admins
          .updateStatusRestored({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdateStatusRestored(response) {
        this.$emit('onUpdateStatusRestored', response)
      }
    }
  }
</script>
