import Vue from 'vue'

import Qs from 'qs'

class UserHistories {
  async searchUser({userId, params}) {
    return await Vue.axios
      .get('app/user-histories/search/by-user/' + userId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }
}

const userHistories = new UserHistories()

export default userHistories
