<template>
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          :icon="icon"
          :title="title"
          :hasFilter="hasFilter"
          :colspan="colspan"
          :empty="empty"
          :defaultFilters="defaultFilters"
          :defaultOrder="defaultOrder"
          :defaultBy="defaultBy"
          :defaultLimit="defaultLimit"
          :fetchData="fetchData"
          :itemsKey="itemsKey"
      >
        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-col
              cols="12"
              md="6"
          >
            <v-text-field
                v-model="params.query"
                :error-messages="errors.query"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Vyhľadávanie"
                :clearable="true"
                @change="setSearch"
            />
          </v-col>
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-col
              cols="12"
              sm="6"
              md="3"
          >
            <ComboboxRegisterIndexesIndexGroupValue
                v-model="params.Filters.subject"
                :error-messages="errors.Filters?.subject"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Predmet"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="mail_subject"
            />
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="3"
          >
            <AutocompleteRegisterIndexesIndexGroupValue
                v-model="params.Filters.status"
                :error-messages="errors.Filters?.status"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Stav"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="mail_status"
            />
          </v-col>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              orderList,
              byList,
              setOrderBy
            }"
        >
          <th>
            Od
          </th>

          <th>
            Komu
          </th>

          <th>
            Predmet
          </th>

          <th>
            Stav
          </th>

          <th>
            <OrderByList
                label="Dátum a čas zadania"
                value="maidat.createdAt"
                :order="order"
                :by="by"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            <OrderByList
                label="Dátum a čas odoslania"
                value="maidat.updatedAt"
                :order="order"
                :by="by"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item
            }"
        >
          <td>
            <a
                :href="'mailto: ' + Item.fromEmail"
            >
              <TruncateHelper
                  :title="Item.fromEmail"
                  maxWidth="300px"
              >
                {{ Item.fromEmail }}

                <template
                    v-if="Item.fromName"
                >
                  ({{ Item.fromName }})
                </template>
              </TruncateHelper>
            </a>
          </td>

          <td>
            <a
                :href="'mailto: ' + Item.toEmail"
            >
              <TruncateHelper
                  :title="Item.toEmail"
                  maxWidth="300px"
              >
                {{ Item.toEmail }}

                <template
                    v-if="Item.toName"
                >
                  ({{ Item.toName }})
                </template>
              </TruncateHelper>
            </a>
          </td>

          <td>
            <TruncateHelper
                :title="Item.subject"
                maxWidth="300px"
            >
              {{ Item.subject }}
            </TruncateHelper>
          </td>

          <td>
            <v-badge
                :inline="true"
                :color="Item.statusColorEnum"
                :content="Item.statusEnum"
            />
          </td>

          <td>
            {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.updatedAt"
            >
              {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
            </SpanIsEmpty>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/mails/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </td>
        </template>

        <template
            v-slot:pagination-count="{
              limit,
              setLimit
            }"
        >
          <LimitPagination
              v-for="(_limit, i) in [10, 25, 50, 100]"
              :key="i"
              :label="_limit"
              :value="_limit"
              :limit="limit"
              @setLimit="setLimit"
          />
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import Mails from '@/services/mails'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  import SpanIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Span'

  import ComboboxRegisterIndexesIndexGroupValue from '@/components/Register/ComboboxIndexesIndexGroupValue'
  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Mails',
    components: {
      BlankPage,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper,
      SpanIsEmpty,
      ComboboxRegisterIndexesIndexGroupValue,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        icon: 'mdi-email',
        hasFilter: true,
        colspan: 7,
        empty: 'Nenašli sa žiadne maily.',
        itemsKey: 'mails',
        defaultFilters: {
          subject: null,
          status: null
        },
        defaultOrder: 'maidat.createdAt',
        defaultBy: 'DESC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return Mails
          .search({
            params: params
          })
      },
      setTitle() {
        return 'Maily'
      }
    }
  }
</script>
