import Vue from 'vue'

import Vuetify from 'vuetify'

Vue.use(Vuetify)

const options = {
  theme: {
    themes: {
      light: {
        primary: '#080b40',
        secondary: '#00AEEF'
      },
      dark: {
        primary: '#00AEEF',
        secondary: '#080b40'
      }
    }
  }
}
const vuetify = new Vuetify(options)

export default vuetify
