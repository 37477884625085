import Vue from 'vue'

import Qs from 'qs'

class Mails {
  async search({params}) {
    return await Vue.axios
      .get('app/mails/search', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/mails/' + id)
  }
}

const mails = new Mails()

export default mails
