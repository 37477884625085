<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :saveData="profileSetAdminData"
      @onSave="onProfileSetAdmin"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Osobné údaje"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.username"
                    :error-messages="errors.username"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Prezývka"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.gender"
                    :error-messages="errors.gender"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Pohlavie"
                    indexGroupValue="admin_gender"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.firstName"
                    :error-messages="errors.firstName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Meno"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.lastName"
                    :error-messages="errors.lastName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Priezvisko"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.email"
                    :error-messages="errors.email"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="E-mail"
                    type="email"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.phone"
                    :error-messages="errors.phone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                    :persistent-hint="true"
                    hint="Uvádzajte v tvare 090XXXXXXX"
                />
              </v-col>
            </v-row>
          </template>
        </CardPart>
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Profile from '@/services/profile'

  import SaveModal from '@/components/_Part/_Modal/Save'

  import CardPart from '@/components/_Part/Card'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'ProfileSetAdmin',
    components: {
      SaveModal,
      CardPart,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        color: 'warning',
        icon: 'mdi-pencil',
        maxWidth: 750,
        buttonYes: 'Nastaviť',
        itemKey: 'User',
      }
    },
    methods: {
      fetchData() {
        return Profile
          .exist()
      },
      setTitle() {
        return 'Nastaviť profil'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setItemForm(Item) {
        return {
          username: Item.username,
          gender: Item.gender,
          firstName: Item.firstName,
          lastName: Item.lastName,
          email: Item.email,
          phone: Item.phone
        }
      },
      profileSetAdminData(ItemForm) {
        return Profile
          .set({
            data: ItemForm
          })
      },
      onProfileSetAdmin() {
        this.$store.dispatch('auth/check')
      }
    }
  }
</script>
