import Vue from 'vue'

class NewPassword {
  async restore({data}) {
    return await Vue.axios
      .post('new-password/restore', data)
  }

  async exist({newPasswordToken}) {
    return await Vue.axios
      .get('new-password/exist/' + newPasswordToken)
  }

  async set({newPasswordToken, data}) {
    return await Vue.axios
      .put('new-password/set/' + newPasswordToken, data)
  }
}

const newPassword = new NewPassword()

export default newPassword
