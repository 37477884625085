var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PagePart',{attrs:{"fetchData":_vm.fetchData,"itemKey":_vm.itemKey,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs,"failLink":_vm.failLink},on:{"onSuccess":_vm.onSuccess,"onFail":_vm.onFail},scopedSlots:_vm._u([{key:"default",fn:function({
        loading,
        calls,
        Item,
        title,
        subtitle,
        backLink,
        refresh,
        reload
      }){return [_c('CardPart',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"title":title,"subtitle":subtitle},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-spacer'),_vm._t("action",null,{"calls":calls,"Item":Item,"refresh":refresh,"reload":reload}),(_vm.buttonHistory)?_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":backLink,"exact":true}},[_c('v-icon',[_vm._v(" mdi-history ")])],1):_vm._e(),(_vm.buttonRefresh)?_c('v-btn',{attrs:{"icon":true,"outlined":true,"loading":loading,"disabled":loading,"color":"default"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return refresh.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1):_vm._e()]},proxy:true},{key:"default",fn:function(){return [(loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),_vm._t("default",null,{"calls":calls,"Item":Item,"refresh":refresh,"reload":reload})]},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }