var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"icon":_vm.icon,"title":title,"colspan":_vm.colspan,"empty":_vm.empty,"defaultOrder":_vm.defaultOrder,"defaultBy":_vm.defaultBy,"defaultLimit":_vm.defaultLimit,"fetchData":_vm.fetchData,"itemsKey":_vm.itemsKey},scopedSlots:_vm._u([{key:"action",fn:function({
            init
          }){return [_c('Create',{on:{"onCreate":init}})]}},{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})],1)]}},{key:"list-head",fn:function({
            order,
            by,
            setOrderBy
          }){return [_c('th',{staticClass:"text-left fixed"},[_vm._v(" Názov ")]),_c('th',[_c('OrderByList',{attrs:{"label":"Poradie","value":"indgrodat.sort","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_vm._v(" Hodnota ")]),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item,
            init
          }){return [_c('td',{staticClass:"text-left fixed"},[_c('TruncateHelper',{attrs:{"title":Item.title,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.title)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(Item.sort)+" ")]),_c('td',[_c('TruncateHelper',{attrs:{"title":Item.value,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.value)+" ")])],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/index-groups/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_c('v-badge',{attrs:{"color":"info","value":Item.indexesTotal,"content":Item.indexesTotal}})],1),_c('CreateIndexGroupIndex',{attrs:{"IndexGroup":Item},on:{"onCreateIndexGroup":init}}),_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":init}}),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":init}}):_vm._e()],1)]}},{key:"pagination-count",fn:function({
            limit,
            setLimit
          }){return _vm._l(([10, 25, 50, 100]),function(_limit,i){return _c('LimitPagination',{key:i,attrs:{"label":_limit,"value":_limit,"limit":limit},on:{"setLimit":setLimit}})})}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }