import Vue from 'vue'

import Qs from 'qs'

class Volunteers {
  async search({params}) {
    return await Vue.axios
      .get('app/volunteers/search', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/volunteers', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/volunteers/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/volunteers/' + id, data)
  }

  async updateNote({id, data}) {
    return await Vue.axios
      .put('app/volunteers/' + id + '/note', data)
  }

  async updateStatusConfirmed({id, data}) {
    return await Vue.axios
      .put('app/volunteers/' + id + '/status/confirmed', data)
  }

  async updateStatusCanceled({id, data}) {
    return await Vue.axios
      .put('app/volunteers/' + id + '/status/canceled', data)
  }

  async updateStatusRestored({id, data}) {
    return await Vue.axios
      .put('app/volunteers/' + id + '/status/restored', data)
  }

  async updateMass({data}) {
    return await Vue.axios
      .put('app/volunteers/mass', data)
  }

  async updateMassStatusConfirmed({data}) {
    return await Vue.axios
      .put('app/volunteers/mass/status/confirmed', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/volunteers/' + id)
  }

  async uploadPhoto({id, data}) {
    if (id) {
      return await Vue.axios
        .post('app/volunteers/' + id + '/upload/photo', data)
    } else {
      return await Vue.axios
        .post('app/volunteers/upload/photo', data)
    }
  }
}

const volunteers = new Volunteers()

export default volunteers
