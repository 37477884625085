<template>
  <!-- TODO -->
  <FormPart
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
  >
    <template
        v-slot:default="{
          loading,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            v-if="Quizz.questions.length > 0"
            cols="12"
        >
          <v-row>
            <v-col
                v-for="(_question, i) in Quizz.questions"
                :key="i"
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h2
                      v-if="_question.title"
                  >
                    {{ i + 1 }}. {{ _question.title }}
                  </h2>

                  <p
                      v-if="_question.help"
                  >
                    {{ _question.help }}
                  </p>
                </v-col>

                <v-col
                    v-if="_question.answers.length > 0"
                    cols="12"
                >
                  <v-row>
                    <v-col
                        v-for="(_answer, j) in _question.answers"
                        :key="j"
                        cols="12"
                    >
                      <v-checkbox
                          v-model="ItemForm.checkQuestions[i].answers[j]"
                          :true-value="1"
                          :false-value="0"
                          :dense="true"
                          hide-details="auto"
                          :label="_answer.title"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            cols="12"
        >
          <v-btn
              type="submit"
              :large="true"
              :block="true"
              :loading="loading"
              :disabled="loading"
              color="primary"
          >
            <v-icon>
              mdi-send
            </v-icon>

            Odoslať
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import AttemptsAuth from '@/services/attempts-auth'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Form',
    props: {
      Quizz: {
        type: Object,
        required: true
      }
    },
    components: {
      FormPart
    },
    data() {
      return {
        thanks: false
      }
    },
    methods: {
      setItemForm() {
        return {
          checkQuestions: this.Quizz.defaultQuestions
        }
      },
      submitData(ItemForm) {
        return AttemptsAuth
          .createQuizz({
            quizzId: this.Quizz.id,
            data: ItemForm
          })
      },
      onSuccess(response) {
        this.$router.push('/attempts-auth/' + response.data.data.id)
      }
    }
  }
</script>
