<template>
  <v-list-item
      v-if="!item.subItems"
      :to="item.to"
  >
    <v-list-item-icon>
      <v-icon>
        {{ item.icon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title>
      {{ item.title }}
    </v-list-item-title>
  </v-list-item>

  <v-list-group
      v-else
      :value="item.value"
  >
    <template
        v-slot:activator
    >
      <v-list-item-icon>
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title>
        {{ item.title }}
      </v-list-item-title>
    </template>

    <Menu
        v-for="(_subItem, j) in item.subItems"
        :key="j"
        :item="_subItem"
    />
  </v-list-group>
</template>

<script>
  export default {
    name: 'Menu',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>
