var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CardPart',{attrs:{"icon":_vm.icon,"maxWidth":_vm.maxWidth,"title":_vm.title + ' (' + _vm.total + ')',"subtitle":_vm.subtitle},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-spacer'),_vm._t("action",null,{"init":_vm.init}),_c('v-btn',{attrs:{"icon":true,"outlined":true,"loading":_vm.loading,"disabled":_vm.loading,"color":"default"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.init.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]},proxy:true},{key:"default",fn:function(){return [_c('v-row',[_c('BlockGrid',{attrs:{"hasFilter":_vm.hasFilter,"hasSearch":_vm.hasSearch},on:{"setParams":_vm.setParams},scopedSlots:_vm._u([{key:"search",fn:function({
              setSearch
            }){return [_vm._t("search",null,{"params":_vm.params,"errors":_vm.errors,"setSearch":setSearch})]}},{key:"filter",fn:function({
              setFilter
            }){return [_vm._t("filter",null,{"params":_vm.params,"errors":_vm.errors,"setFilter":setFilter})]}}],null,true)}),_c('ListGrid',{attrs:{"loading":_vm.loading,"items":_vm.items,"hasStandard":_vm.hasStandard,"standardClass":_vm.standardClass,"hasExtra":_vm.hasExtra,"extraClass":_vm.extraClass,"colspan":_vm.colspan,"empty":_vm.empty},on:{"setParams":_vm.setParams},scopedSlots:_vm._u([{key:"head",fn:function({
              setOrderBy
            }){return [_vm._t("list-head",null,{"items":_vm.items,"order":_vm.params.order,"by":_vm.params.by,"init":_vm.init,"setOrderBy":setOrderBy})]}},{key:"body-standard",fn:function({
              Item
            }){return [_vm._t("list-body-standard",null,{"items":_vm.items,"Item":Item,"init":_vm.init})]}},{key:"body-extra",fn:function({
              Item
            }){return [_vm._t("list-body-extra",null,{"items":_vm.items,"Item":Item,"init":_vm.init})]}}],null,true)}),_c('PaginationGrid',{attrs:{"loading":_vm.loading,"total":_vm.total,"limit":_vm.params.limit,"page":_vm.params.page},on:{"setParams":_vm.setParams},scopedSlots:_vm._u([{key:"count",fn:function({
              setLimit
            }){return [_vm._t("pagination-count",null,{"limit":_vm.params.limit,"setLimit":setLimit})]}}],null,true)})],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }