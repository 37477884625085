<template>
  <!-- TODO -->
  <CardPart
      icon="mdi-bell"
      title="Oznamy"
  >
    <template
        v-slot:default
    >
      <v-tabs
          v-model="tabs"
          :show-arrows="true"
      >
        <v-tab
            v-for="(_information, i) in informations"
            :key="i"
        >
          {{ _information.title }}

          <v-badge
              :inline="true"
              :color="_information.color"
              :content="_information.publishedAt"
          />
        </v-tab>
      </v-tabs>

      <v-tabs-items
          v-model="tabs"
      >
        <v-tab-item
            v-for="(_information, i) in informations"
            :key="i"
        >
          <CardPart>
            <template
                v-slot:default
            >
              <div
                  v-html="_information.content"
              />
            </template>
          </CardPart>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </CardPart>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Informations',
    components: {
      CardPart
    },
    data() {
      return {
        tabs: 0,
        informations: [
          {
            title: 'Informácie k online pohovorom',
            content: '<p>Vážení dobrovoľníci,<br/><br/><b>Online pohovory prebiehajú prostredníctvom aplikácie MS Teams a dĺžka jedného pohovoru je 20 min. Preto Vás prosíme o dochvíľnosť a tiež funkčný mikrofón a kameru na Vašom zariadení, prostredníctvom ktorého sa budete pripájať.</b><br/><br/>Postup:</p><ol><li>Priamo vo Vašom dobrovoľníckom profile v časti Pohovory je zverejnený kalendár s voľnými termínmi, na ktoré sa môžete prihlásiť. Kliknutím na dátum a čas pohovoru si viete rezervovať termín, ktorý Vám vyhovuje. Po jeho rezervácii obdržíte potvrdzovací email.</li><li>Pred plánovaným termínom online pohovoru od nás obdržíte Pozvánku v emailovej podobe spolu s priloženým linkom, prostredníctvom ktorého sa spolu spojíme. Daný link nájdete tiež priamo vo Vašom dobrovoľníckom profile v časti pohovory v konkrétnom dni a hodine, na ktorú ste sa prihlásili.</li><li>V prípade potreby a neočakávaných udalostí je možné z Vašej strany odhlásiť sa z termínu online pohovoru a vybrať si iný termín, ktorý Vám vyhovuje viac.</li></ol><p>V prípade akýchkoľvek otázok nás neváhajte kontaktovať.<br/><br/>Ďakujeme a tešíme sa na stretnutia s Vami.</p>',
            publishedAt: '03.07.2024',
            color: 'error'
          }
        ]
      }
    }
  }
</script>
