<template>
  <UpdateSave
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :updateData="updateData"
      @onUpdate="onUpdate"
  >
    <template
        v-slot:default="{
          Item,
          close,
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-text-field
            v-model="ItemForm.title"
            :error-messages="errors.title"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Názov"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <vue-editor
            v-model="ItemForm.content"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <v-input
            :value="materials"
            :error-messages="errors.materials"
            :filled="true"
            :dense="true"
            hide-details="auto"
            :label="
              materials.length === 0
                ? 'Materiály'
                : null
            "
        >
          <template
              v-slot:default
          >
            &nbsp;

            <v-row>
              <template
                  v-if="materials.length > 0"
              >
                <v-col
                    v-for="(_Material, index) in materials"
                    :key="_Material.id"
                    cols="12"
                >
                  <a
                      :href="$global.cdnUrl() + 'media/files/by-token/'  + _Material.token"
                      target="_blank"
                  >
                    {{ _Material.name }}
                  </a>

                  <v-btn
                      :icon="true"
                      :outlined="true"
                      color="error"
                      @click.stop.prevent="() => {
                        ItemForm.materials.splice(index, 1)

                        materials.splice(index, 1)
                      }"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </template>

              <v-col
                  cols="12"
              >
                <File
                    :id="Item.id"
                    @onFile="(response) => {
                      ItemForm.materials.push(response.data.data.id)

                      materials.push({
                        id: response.data.data.id,
                        token: response.data.data.token,
                        name: response.data.data.name
                      })
                    }"
                />
              </v-col>
            </v-row>
          </template>
        </v-input>
      </v-col>

      <v-col
          cols="12"
      >
        <template
            v-if="Item.status !== 'deactivated'"
        >
          <a
              class="error--text"
              @click.stop.prevent="$refs.updateStatusDeactivated.$el.click()"
          >
            <v-icon
                color="error"
            >
              mdi-toggle-switch-off
            </v-icon>

            Chcem deaktivovať školenie
          </a>

          <UpdateStatusDeactivated
              ref="updateStatusDeactivated"
              :id="Item.id"
              class="d-none"
              @onUpdateStatusDeactivated="(response) => {
                close()

                onUpdate(response)
              }"
          />
        </template>

        <template
            v-else
        >
          <a
              class="success--text"
              @click.stop.prevent="$refs.updateStatusActivated.$el.click()"
          >
            <v-icon
                color="success"
            >
              mdi-toggle-switch
            </v-icon>

            Chcem aktivovať školenie
          </a>

          <UpdateStatusActivated
              ref="updateStatusActivated"
              :id="Item.id"
              class="d-none"
              @onUpdateStatusActivated="(response) => {
                close()

                onUpdate(response)
              }"
          />
        </template>
      </v-col>
    </template>
  </UpdateSave>
</template>

<script>
  import Courses from '@/services/courses'

  import File from '@/components/Courses/File'
  import UpdateStatusDeactivated from '@/components/Courses/UpdateStatusDeactivated'
  import UpdateStatusActivated from '@/components/Courses/UpdateStatusActivated'

  import UpdateSave from '@/components/_Part/_Modal/_Save/Update'

  export default {
    name: 'Update',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      File,
      UpdateStatusDeactivated,
      UpdateStatusActivated,
      UpdateSave
    },
    data() {
      return {
        maxWidth: 750,
        itemKey: 'Course',
        materials: []
      }
    },
    methods: {
      fetchData() {
        return Courses
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Upraviť školenie'
      },
      setSubtitle(Item) {
        return Item.title
      },
      setItemForm(Item) {
        this.materials = Item.materials.map((_Material) => ({
          id: _Material.id,
          token: _Material.token,
          name: _Material.name
        }))

        return {
          title: Item.title,
          content: Item.content,
          materials: Item.materials.map((_Material) => _Material.id)
        }
      },
      updateData(ItemForm) {
        return Courses
          .update({
            id: this.id,
            data: ItemForm
          })
      },
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
