<template>
  <!-- TODO -->
  <CardPart
      icon="mdi-contacts"
      title="Kontakty"
  >
    <template
        v-slot:default
    >
      <v-row>
        <v-col
            v-for="(_contact, i) in contacts"
            :key="i"
            cols="12"
            sm="6"
            md="4"
        >
          <CardPart
              :title="_contact.name"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <h3>
                    {{ _contact.position }}
                  </h3>

                  <p>
                    E-mail:

                    <a
                        :href="'mailto:' +_contact.email"
                    >
                      {{ _contact.email }}
                    </a>

                    <br/>

                    Telefón:

                    <a
                        :href="'tel:' +_contact.phone"
                    >
                      {{ _contact.phone }}
                    </a>
                  </p>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>
      </v-row>
    </template>
  </CardPart>
</template>

<script>
  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Contacts',
    components: {
      CardPart
    },
    data() {
      return {
        contacts: [
          {
            name: 'Júlia Jančíková',
            position: 'Manažérka dobrovoľníkov',
            email: 'julia.jancikova@sfzevent.sk',
            phone: '0902139256'
          }
        ]
      }
    }
  }
</script>
