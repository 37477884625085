<template>
  <v-main
      class="custom-main custom-main-error"
  >
    <v-container
        :fluid="true"
        :fill-height="true"
    >
      <v-row
          align="center"
      >
        <v-col
            cols="12"
            lg="6"
        >
          <v-container>
            <v-row>
              <v-col
                  sm="2"
                  md="3"
                  lg="2"
                  class="d-none d-sm-block"
              />

              <v-col
                  cols="12"
                  sm="8"
                  md="6"
                  lg="8"
              >
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-row>
                      <v-spacer/>

                      <v-col
                          cols="auto"
                      >
                        <router-link
                            to="/"
                        >
                          <v-img
                              :src="$global.logo()"
                              alt="Logo"
                              max-width="122px"
                              max-height="48px"
                          />
                        </router-link>
                      </v-col>

                      <v-spacer/>
                    </v-row>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <router-view/>
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-row>
                      <v-col
                          cols="auto"
                      >
                        <a
                            href="https://under21.futbalsfz.sk/"
                            target="_blank"
                        >
                          <v-img
                              :src="$global.copyright()"
                              alt="Logo"
                              max-width="28px"
                              max-height="28px"
                          />
                        </a>
                      </v-col>

                      <v-spacer/>

                      <v-col
                          cols="auto"
                      >
                        <v-badge
                            :inline="true"
                            :content="'v'+ $global.version()"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  sm="2"
                  md="3"
                  lg="2"
                  class="d-none d-sm-block"
              />
            </v-row>
          </v-container>
        </v-col>

        <v-col
            lg="6"
            class="custom-main-bg d-none d-lg-block"
        />
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'PageError'
  }
</script>
