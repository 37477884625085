<template>
  <CreateSave
      :maxWidth="maxWidth"
      :setTitle="setTitle"
      :setItemForm="setItemForm"
      :createData="createData"
      @onCreate="onCreate"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <AutocompleteRegisterIndexesIndexGroupValue
            v-model="ItemForm.type"
            :error-messages="errors.type"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Typ"
            indexGroupValue="admin_type"
        />
      </v-col>

      <v-col
          cols="12"
      >
        <CardPart
            subtitle="Osobné údaje"
        >
          <template
              v-slot:default
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.username"
                    :error-messages="errors.username"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Prezývka"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <AutocompleteRegisterIndexesIndexGroupValue
                    v-model="ItemForm.gender"
                    :error-messages="errors.gender"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Pohlavie"
                    indexGroupValue="admin_gender"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.firstName"
                    :error-messages="errors.firstName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Meno"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.lastName"
                    :error-messages="errors.lastName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Priezvisko"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.email"
                    :error-messages="errors.email"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="E-mail"
                    type="email"
                />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="ItemForm.phone"
                    :error-messages="errors.phone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                    :persistent-hint="true"
                    hint="Uvádzajte v tvare 090XXXXXXX"
                />
              </v-col>
            </v-row>
          </template>
        </CardPart>
      </v-col>
    </template>
  </CreateSave>
</template>

<script>
  import Admins from '@/services/admins'

  import CreateSave from '@/components/_Part/_Modal/_Save/Create'

  import CardPart from '@/components/_Part/Card'

  import AutocompleteRegisterIndexesIndexGroupValue from '@/components/Register/AutocompleteIndexesIndexGroupValue'

  export default {
    name: 'Create',
    emits: [
      'onCreate'
    ],
    components: {
      CreateSave,
      CardPart,
      AutocompleteRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        maxWidth: 750
      }
    },
    methods: {
      setTitle() {
        return 'Vytvoriť administrátora'
      },
      setItemForm() {
        return {
          type: null,
          username: null,
          gender: null,
          firstName: null,
          lastName: null,
          email: null,
          phone: null
        }
      },
      createData(ItemForm) {
        return Admins
          .create({
            data: ItemForm
          })
      },
      onCreate(response) {
        this.$emit('onCreate', response)
      }
    }
  }
</script>
