import Vue from 'vue'

import VueRouter from 'vue-router'

import AuthHome from '@/middleware/auth/home'
import AuthError from '@/middleware/auth/error'
import AuthUnLogged from '@/middleware/auth/unlogged'
import AuthLogged from '@/middleware/auth/logged'
import AuthBase from '@/middleware/auth/base'

import PageAuth from '@/views/PageAuth'
import Login from '@/views/Login'
import NewPasswordRestore from '@/views/NewPasswordRestore'
import NewPasswordSet from '@/views/NewPasswordSet'
import PageBackend from '@/views/PageBackend'
import Dashboard from '@/views/Dashboard'
import Admins from '@/views/Admins'
import Admin from '@/views/Admin'
import Volunteers from '@/views/Volunteers'
import Volunteer from '@/views/Volunteer'
import Interviews from '@/views/Interviews'
import Courses from '@/views/Courses'
import Course from '@/views/Course'
import Quizz from '@/views/Quizz'
import Attempt from '@/views/Attempt'
import IndexGroups from '@/views/IndexGroups'
import IndexGroup from '@/views/IndexGroup'
import AuditLogs from '@/views/AuditLogs'
import AuditLog from '@/views/AuditLog'
import Mails from '@/views/Mails'
import Mail from '@/views/Mail'
import Files from '@/views/Files'
import File from '@/views/File'
import InterviewsAuth from '@/views/InterviewsAuth'
import CoursesAuth from '@/views/CoursesAuth'
import CourseAuth from '@/views/CourseAuth'
import QuizzAuth from '@/views/QuizzAuth'
import AttemptAuth from '@/views/AttemptAuth'
import PageFrontend from '@/views/PageFrontend'
import Registration from '@/views/Registration'
import PageError from '@/views/PageError'
import Error from '@/views/Error'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      role: 'ROLE_USER'
    },
    beforeEnter: AuthHome
  },
  {
    path: '*',
    meta: {
      role: 'ROLE_USER'
    },
    beforeEnter: AuthError
  },
  {
    path: '/',
    component: PageAuth,
    children: [
      {
        path: '/login',
        component: Login,
        beforeEnter: AuthUnLogged
      },
      {
        path: '/new-password/restore',
        component: NewPasswordRestore,
        beforeEnter: AuthUnLogged
      },
      {
        path: '/new-password/set/:newPasswordToken',
        component: NewPasswordSet,
        beforeEnter: AuthUnLogged
      }
    ]
  },
  {
    path: '/',
    component: PageBackend,
    children: [
      {
        path: '/dashboard',
        component: Dashboard,
        meta: {
          role: 'ROLE_USER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/admins',
        component: Admins,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/admins/:id',
        component: Admin,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/volunteers',
        component: Volunteers,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/volunteers/:id',
        component: Volunteer,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/interviews',
        component: Interviews,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/courses',
        component: Courses,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/courses/:id',
        component: Course,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/quizzes/:id',
        component: Quizz,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/attempts/:id',
        component: Attempt,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/index-groups',
        component: IndexGroups,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/index-groups/:id',
        component: IndexGroup,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/audit-logs',
        component: AuditLogs,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/audit-logs/:id',
        component: AuditLog,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/mails',
        component: Mails,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/mails/:id',
        component: Mail,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/files',
        component: Files,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/files/:id',
        component: File,
        meta: {
          role: 'ROLE_MASTER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/interviews-auth',
        component: InterviewsAuth,
        meta: {
          role: 'ROLE_VOLUNTEER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/courses-auth',
        component: CoursesAuth,
        meta: {
          role: 'ROLE_VOLUNTEER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/courses-auth/:id',
        component: CourseAuth,
        meta: {
          role: 'ROLE_VOLUNTEER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/quizzes-auth/:id',
        component: QuizzAuth,
        meta: {
          role: 'ROLE_VOLUNTEER'
        },
        beforeEnter: AuthLogged
      },
      {
        path: '/attempts-auth/:id',
        component: AttemptAuth,
        meta: {
          role: 'ROLE_VOLUNTEER'
        },
        beforeEnter: AuthLogged
      }
    ]
  },
  {
    path: '/',
    component: PageFrontend,
    children: [
      {
        path: '/registration',
        component: Registration,
        beforeEnter: AuthUnLogged
      }
    ]
  },
  {
    path: '/',
    component: PageError,
    children: [
      {
        path: '/error',
        component: Error,
        beforeEnter: AuthBase
      }
    ]
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
