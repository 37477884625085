<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :buttonNo="buttonNo"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :setItemForm="setItemForm"
      :saveData="updateData"
      @onSave="onUpdate"
  >
    <template
        v-slot:default="{
          Item,
          close,
          errors,
          ItemForm
        }"
    >
      <slot
          name="default"
          :Item="Item"
          :close="close"
          :errors="errors"
          :ItemForm="ItemForm"
      />
    </template>
  </SaveModal>
</template>

<script>
  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'Update',
    props: {
      color: {
        type: String,
        default: 'warning'
      },
      icon: {
        type: String,
        default: 'mdi-pencil'
      },
      maxWidth: {
        type: Number,
        required: true
      },
      buttonYes: {
        type: String,
        default: 'Upraviť'
      },
      buttonNo: {
        type: String,
        default: 'Zavrieť'
      },
      fetchData: {
        type: Function,
        required: true
      },
      itemKey: {
        type: String,
        required: true
      },
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setAlert: {
        type: Function,
        default: () => null
      },
      setItemForm: {
        type: Function,
        default: () => {}
      },
      updateData: {
        type: Function,
        required: true
      }
    },
    emits: [
      'onUpdate'
    ],
    components: {
      SaveModal
    },
    methods: {
      onUpdate(response) {
        this.$emit('onUpdate', response)
      }
    }
  }
</script>
