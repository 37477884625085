<template>
  <AutomcompleteSelect
      :fetchData="fetchData"
      itemsKey="courses"
      v-bind="{
        ...$attrs,
        itemValue: 'id',
        itemText: 'title'
      }"
      v-on="$listeners"
  />
</template>

<script>
  import Courses from '@/services/courses'

  import AutomcompleteSelect from '@/components/_Part/_Field/_Select/Autocomplete'

  export default {
    name: 'Autocomplete',
    components: {
      AutomcompleteSelect
    },
    methods: {
      fetchData() {
        return Courses
          .index()
      }
    }
  }
</script>
