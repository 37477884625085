<template>
  <ModalPart
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
  >
    <template
        v-slot:default="{
          Item,
          close
        }"
    >
      <FormPart
          :Item="Item"
          :setAlert="setAlert"
          :setItemForm="setItemForm"
          :submitData="saveData"
          @onSuccess="(response) => {
            onSave(response)

            close()
          }"
          @onFail="close"
      >
        <template
            v-slot:default="{
              loading,
              errors,
              alert,
              ItemForm
            }"
        >
          <v-row>
            <v-col
                v-if="alert"
                cols="12"
            >
              <v-alert
                  type="info"
              >
                {{ alert }}
              </v-alert>
            </v-col>

            <slot
                name="default"
                :Item="Item"
                :close="close"
                :errors="errors"
                :ItemForm="ItemForm"
            />

            <v-col
                cols="12"
            >
              <v-btn
                  type="submit"
                  :rounded="true"
                  :outlined="true"
                  :loading="loading"
                  :disabled="loading"
                  :color="color"
              >
                <v-icon>
                  {{ icon }}
                </v-icon>

                {{ buttonYes }}
              </v-btn>

              <v-btn
                  :rounded="true"
                  :outlined="true"
                  color="default"
                  @click.stop.prevent="close"
              >
                <v-icon>
                  mdi-close
                </v-icon>

                {{ buttonNo }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </FormPart>
    </template>
  </ModalPart>
</template>

<script>
  import ModalPart from '@/components/_Part/Modal'
  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Save',
    props: {
      color: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      maxWidth: {
        type: Number,
        required: true
      },
      buttonYes: {
        type: String,
        required: true
      },
      buttonNo: {
        type: String,
        default: 'Zavrieť'
      },
      fetchData: {
        type: Function,
        default: null
      },
      itemKey: {
        type: String,
        default: null
      },
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setAlert: {
        type: Function,
        default: () => null
      },
      setItemForm: {
        type: Function,
        default: () => {}
      },
      saveData: {
        type: Function,
        required: true
      }
    },
    emits: [
      'onSave'
    ],
    components: {
      ModalPart,
      FormPart
    },
    methods: {
      onSave(response) {
        this.$emit('onSave', response)
      }
    }
  }
</script>
