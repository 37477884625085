<template>
  <PagePart
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
  >
    <template
        v-slot:default="{
          loading,
          calls,
          title,
          subtitle,
          backLink,
          refresh,
          reload
        }"
    >
      <slot
          name="default"
          :loading="loading"
          :calls="calls"
          :title="title"
          :subtitle="subtitle"
          :backLink="backLink"
          :refresh="refresh"
          :reload="reload"
      />
    </template>
  </PagePart>
</template>

<script>
  import PagePart from '@/components/_Part/Page'

  export default {
    name: 'Blank',
    props: {
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setBreadcrumbs: {
        type: Function,
        default: () => []
      }
    },
    components: {
      PagePart
    }
  }
</script>
