<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setItemForm="setItemForm"
      :saveData="profileSetStatusVolunteerUnlockedData"
      @onSave="onProfileSetStatusVolunteerUnlocked"
  >
    <template
        v-slot:default="{
          errors,
          ItemForm
        }"
    >
      <v-col
          cols="12"
      >
        <v-textarea
            v-model="ItemForm.note"
            :error-messages="errors.note"
            :filled="true"
            :dense="true"
            hide-details="auto"
            label="Prosím uveďte dôvod obnovenia Vašej registrácie"
            :rows="2"
        />
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Profile from '@/services/profile'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'ProfileSetStatusVolunteerUnlocked',
    emits: [
      'onProfileSetStatusVolunteerUnlocked'
    ],
    components: {
      SaveModal
    },
    data() {
      return {
        color: 'success',
        icon: 'mdi-restore',
        maxWidth: 500,
        buttonYes: 'Obnoviť registráciu',
        itemKey: 'User'
      }
    },
    methods: {
      fetchData() {
        return Profile
          .exist()
      },
      setTitle() {
        return 'Obnoviť registráciu dobrovoľníka'
      },
      setSubtitle(Item) {
        return Item.name
      },
      setItemForm() {
        return {
          note: null
        }
      },
      profileSetStatusVolunteerUnlockedData(ItemForm) {
        return Profile
          .setStatusVolunteerUnlocked({
            data: ItemForm
          })
      },
      onProfileSetStatusVolunteerUnlocked(response) {
        this.$emit('onProfileSetStatusVolunteerUnlocked', response)
      }
    }
  }
</script>
