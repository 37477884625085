import Vue from 'vue'

import Qs from 'qs'

class QuizzesAuth {
  async searchCourse({courseId, params}) {
    return await Vue.axios
      .get('app/quizzes/auth/search/by-course/' + courseId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('app/quizzes/auth/' + id)
  }
}

const quizzesAuth = new QuizzesAuth()

export default quizzesAuth
