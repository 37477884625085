<template>
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <CardPart
          :icon="icon"
          :title="title"
      >
        <template
            v-slot:default
        >
          <v-row>
            <v-col
                cols="12"
            >
              <p>
                Z dôvodu dlhšej nečinnosti ste boli automaticky odhlásený zo systému. Pre opätovné prihlásenie prosím kliknite

                <router-link
                    to="/"
                >
                  tu
                </router-link>
              </p>
            </v-col>

            <v-col
                cols="12"
            >
              <p>
                V prípade, že prihlásenie nefunguje, kontaktujte nás prosím na emailovej adrese:

                <a
                    href="mailto: julia.jancikova@sfzevent.sk"
                >
                  julia.jancikova@sfzevent.sk
                </a>
              </p>
            </v-col>
          </v-row>
        </template>
      </CardPart>
    </template>
  </BlankPage>
</template>

<script>
  import BlankPage from '@/components/_Part/_Page/Blank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Error',
    components: {
      BlankPage,
      CardPart
    },
    data() {
      return {
        icon: 'mdi-alert'
      }
    },
    methods: {
      setTitle() {
        return 'Chyba'
      }
    }
  }
</script>
