<template>
  <NotBlankPage
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:default="{
          Item
        }"
    >
      <v-row>
        <v-col
            v-if="Item.content"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <div
                  v-html="Item.content"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <CardPart
              icon="mdi-attachment"
              :title="'Materiály (' + Item.materials.length + ')'"
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <ColumnsIsEmpty
                  :value="Item.materials.length > 0"
              >
                <v-col
                    v-for="(_Material) in Item.materials"
                    :key="_Material.id"
                    cols="auto"
                    align="center"
                >
                  <p>
                    <a
                        :href="$global.cdnUrl() + 'media/files/by-token/' + _Material.token"
                        target="_blank"
                    >
                      <v-icon
                          :large="true"
                      >
                        mdi-file
                      </v-icon>

                      <br/>

                      {{ _Material.name }}
                    </a>
                  </p>
                </v-col>
              </ColumnsIsEmpty>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <Quizzes
              :Course="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import CoursesAuth from '@/services/courses-auth'

  import Quizzes from '@/components/CourseAuth/Quizzes'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  import ColumnsIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Columns'

  export default {
    name: 'CourseAuth',
    components: {
      Quizzes,
      NotBlankPage,
      CardPart,
      ColumnsIsEmpty
    },
    data() {
      return {
        icon: 'mdi-book',
        Item: 'Course',
        failLink: '/dashboard'
      }
    },
    methods: {
      fetchData() {
        return CoursesAuth
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setSubtitle() {
        return 'Školenie'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Školenia',
            to: '/courses-auth',
            exact: true
          }
        ]
      }
    }
  }
</script>
