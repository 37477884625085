import Vue from 'vue'

import Qs from 'qs'

class Quizzes {
  async searchCourse({courseId, params}) {
    return await Vue.axios
      .get('app/quizzes/search/by-course/' + courseId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async createCourse({courseId, data}) {
    return await Vue.axios
      .post('app/quizzes/by-course/' + courseId, data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/quizzes/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/quizzes/' + id, data)
  }

  async updateStatusDeactivated({id, data}) {
    return await Vue.axios
      .put('app/quizzes/' + id + '/status/deactivated', data)
  }

  async updateStatusActivated({id, data}) {
    return await Vue.axios
      .put('app/quizzes/' + id + '/status/activated', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/quizzes/' + id)
  }
}

const quizzes = new Quizzes()

export default quizzes
