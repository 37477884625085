<template>
  <NotBlankPage
      :maxWidth="maxWidth"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <Update
          :id="Item.id"
          @onUpdate="refresh"
      />

      <Delete
          v-if="Item.deletable"
          :id="Item.id"
          @onDelete="() => {
            $router.push('/index-groups')
          }"
      />
    </template>

    <template
        v-slot:default="{
          calls,
          Item,
          reload
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <Indexes
              :key="calls"
              :IndexGroup="Item"
              @reload="reload"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import IndexGroups from '@/services/index-groups'

  import Update from '@/components/IndexGroups/Update'
  import Delete from '@/components/IndexGroups/Delete'

  import Indexes from '@/components/IndexGroup/Indexes'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  export default {
    name: 'IndexGroup',
    components: {
      Update,
      Delete,
      Indexes,
      NotBlankPage
    },
    data() {
      return {
        maxWidth: 'calc(100% - 168px)',
        icon: 'mdi-forum',
        Item: 'IndexGroup',
        failLink: '/index-groups'
      }
    },
    methods: {
      fetchData() {
        return IndexGroups
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setSubtitle() {
        return 'Skupina číselníkov'
      },
      setBreadcrumbs() {
        return [
          {
            text: 'Skupiny číselníkov',
            to: '/index-groups',
            exact: true
          }
        ]
      }
    }
  }
</script>
