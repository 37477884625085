<template>
  <NotBlankPage
      :maxWidth="maxWidth"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="Item"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setBreadcrumbs="setBreadcrumbs"
      :failLink="failLink"
  >
    <template
        v-slot:action="{
          Item,
          refresh
        }"
    >
      <Update
          :id="Item.id"
          @onUpdate="refresh"
      />

      <Delete
          v-if="Item.deletable"
          :id="Item.id"
          @onDelete="() => {
            $router.push('/courses/' + Item.courseId)
          }"
      />
    </template>

    <template
        v-slot:default="{
          calls,
          Item
        }"
    >
      <v-row>
        <v-col
            v-if="Item.status === 'deactivated'"
            cols="12"
        >
          <v-alert
              type="error"
          >
            Test je deaktivovaný.
          </v-alert>
        </v-col>

        <v-col
            v-if="Item.content"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <div
                  v-html="Item.content"
              />
            </template>
          </CardPart>
        </v-col>

        <v-col
            v-if="Item.questions.length > 0"
            cols="12"
        >
          <CardPart
              class="grey lighten-5"
          >
            <template
                v-slot:default
            >
              <v-row>
                <v-col
                    v-for="(_question, i) in Item.questions"
                    :key="i"
                    cols="12"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <h2
                          v-if="_question.title"
                      >
                        {{ i + 1 }}. {{ _question.title }}
                      </h2>

                      <p
                          v-if="_question.help"
                      >
                        {{ _question.help }}
                      </p>
                    </v-col>

                    <v-col
                        v-if="_question.answers.length > 0"
                        cols="12"
                    >
                      <v-row>
                        <v-col
                            v-for="(_answer, j) in _question.answers"
                            :key="j"
                            cols="12"
                        >
                          <v-row
                              align="center"
                          >
                            <v-col
                                cols="auto"
                            >
                              <v-checkbox
                                  v-model="Item.correctQuestions[i].answers[j]"
                                  :true-value="1"
                                  :false-value="0"
                                  :dense="true"
                                  hide-details="auto"
                                  :disabled="true"
                              />
                            </v-col>

                            <v-col
                                cols="auto"
                            >
                              <v-checkbox
                                  v-model="Item.defaultQuestions[i].answers[j]"
                                  :true-value="1"
                                  :false-value="0"
                                  :dense="true"
                                  hide-details="auto"
                                  :readonly="true"
                                  :label="_answer.title"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </CardPart>
        </v-col>

        <v-col
            cols="12"
        >
          <Attempts
              :key="calls"
              :Quizz="Item"
          />
        </v-col>
      </v-row>
    </template>
  </NotBlankPage>
</template>

<script>
  import Quizzes from '@/services/quizzes'

  import Update from '@/components/Quizzes/Update'
  import Delete from '@/components/Quizzes/Delete'

  import Attempts from '@/components/Quizz/Attempts'

  import NotBlankPage from '@/components/_Part/_Page/NotBlank'

  import CardPart from '@/components/_Part/Card'

  export default {
    name: 'Quizz',
    components: {
      Update,
      Delete,
      Attempts,
      NotBlankPage,
      CardPart
    },
    data() {
      return {
        maxWidth: 'calc(100% - 168px)',
        icon: 'mdi-help',
        Item: 'Quizz',
        failLink: '/courses'
      }
    },
    methods: {
      fetchData() {
        return Quizzes
          .read({
            id: this.$route.params.id
          })
      },
      setTitle(Item) {
        return Item.title
      },
      setSubtitle() {
        return 'Test'
      },
      setBreadcrumbs(Item) {
        return [
          {
            text: 'Školenia',
            to: '/courses',
            exact: true
          },
          {
            text: Item.courseTitle,
            to: '/courses/' + Item.courseId,
            exact: true
          }
        ]
      }
    }
  }
</script>
