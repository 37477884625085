<template>
  <SaveModal
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :setTitle="setTitle"
      :setAlert="setAlert"
      :setItemForm="setItemForm"
      :saveData="updateMassData"
      @onSave="onUpdateMass"
  >
    <template
        v-slot:default="{
          close
        }"
    >
      <v-col
          cols="12"
      >
        <a
            class="success--text"
            @click.stop.prevent="$refs.updateMassStatusConfirmed.$el.click()"
        >
          <v-icon
              color="success"
          >
            mdi-check
          </v-icon>

          Chcem schváliť dobrovoľníkov
        </a>

        <UpdateMassStatusConfirmed
            ref="updateMassStatusConfirmed"
            :ids="ids"
            class="d-none"
            @onUpdateMassStatusConfirmed="(response) => {
              close()

              onUpdateMass(response)
            }"
        />
      </v-col>
    </template>
  </SaveModal>
</template>

<script>
  import Volunteers from '@/services/volunteers'

  import UpdateMassStatusConfirmed from '@/components/Volunteers/UpdateMassStatusConfirmed'

  import SaveModal from '@/components/_Part/_Modal/Save'

  export default {
    name: 'UpdateMass',
    props: {
      ids: {
        type: Array,
        required: true
      }
    },
    emits: [
      'onUpdateMass'
    ],
    components: {
      UpdateMassStatusConfirmed,
      SaveModal
    },
    data() {
      return {
        color: 'warning',
        icon: 'mdi-playlist-edit',
        maxWidth: 500,
        buttonYes: 'Hromadne upraviť'
      }
    },
    methods: {
      setTitle() {
        return 'Hromadne upraviť dobrovoľníkov'
      },
      setAlert() {
        return 'Označené položky: ' + this.ids.length
      },
      setItemForm() {
        return {
          users: this.ids
        }
      },
      updateMassData(ItemForm) {
        return Volunteers
          .updateMass({
            data: ItemForm
          })
      },
      onUpdateMass(response) {
        this.$emit('onUpdateMass', response)
      }
    }
  }
</script>
