import Vue from 'vue'

import Qs from 'qs'

class Courses {
  async index() {
    return await Vue.axios
      .get('app/courses')
  }

  async search({params}) {
    return await Vue.axios
      .get('app/courses/search', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/courses', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/courses/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/courses/' + id, data)
  }

  async updateStatusDeactivated({id, data}) {
    return await Vue.axios
      .put('app/courses/' + id + '/status/deactivated', data)
  }

  async updateStatusActivated({id, data}) {
    return await Vue.axios
      .put('app/courses/' + id + '/status/activated', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/courses/' + id)
  }

  async uploadMaterial({id, data}) {
    if (id) {
      return await Vue.axios
        .post('app/courses/' + id + '/upload/material', data)
    } else {
      return await Vue.axios
        .post('app/courses/upload/material', data)
    }
  }
}

const courses = new Courses()

export default courses
