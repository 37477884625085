import Vue from 'vue'

class Registration {
  async signVolunteer({data}) {
    return await Vue.axios
      .post('registration/sign/volunteer', data)
  }

  async uploadPhoto({data}) {
    return await Vue.axios
      .post('registration/upload/photo', data)
  }
}

const registration = new Registration()

export default registration
