import Vue from 'vue'

class Statistics {
  async volunteersByPlaces() {
    return await Vue.axios
      .get('app/statistics/volunteers-by-places')
  }

  async volunteersByStatuses() {
    return await Vue.axios
      .get('app/statistics/volunteers-by-statuses')
  }
}

const statistics = new Statistics()

export default statistics
