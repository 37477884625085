var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotBlankPage',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"fetchData":_vm.fetchData,"itemKey":_vm.Item,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs,"failLink":_vm.failLink},scopedSlots:_vm._u([{key:"action",fn:function({
        Item,
        refresh
      }){return [_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":refresh}}),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":() => {
          _vm.$router.push('/courses/' + Item.courseId)
        }}}):_vm._e()]}},{key:"default",fn:function({
        calls,
        Item
      }){return [_c('v-row',[(Item.status === 'deactivated')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Test je deaktivovaný. ")])],1):_vm._e(),(Item.content)?_c('v-col',{attrs:{"cols":"12"}},[_c('CardPart',{staticClass:"grey lighten-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(Item.content)}})]},proxy:true}],null,true)})],1):_vm._e(),(Item.questions.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('CardPart',{staticClass:"grey lighten-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',_vm._l((Item.questions),function(_question,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_question.title)?_c('h2',[_vm._v(" "+_vm._s(i + 1)+". "+_vm._s(_question.title)+" ")]):_vm._e(),(_question.help)?_c('p',[_vm._v(" "+_vm._s(_question.help)+" ")]):_vm._e()]),(_question.answers.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_question.answers),function(_answer,j){return _c('v-col',{key:j,attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"true-value":1,"false-value":0,"dense":true,"hide-details":"auto","disabled":true},model:{value:(Item.correctQuestions[i].answers[j]),callback:function ($$v) {_vm.$set(Item.correctQuestions[i].answers, j, $$v)},expression:"Item.correctQuestions[i].answers[j]"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"true-value":1,"false-value":0,"dense":true,"hide-details":"auto","readonly":true,"label":_answer.title},model:{value:(Item.defaultQuestions[i].answers[j]),callback:function ($$v) {_vm.$set(Item.defaultQuestions[i].answers, j, $$v)},expression:"Item.defaultQuestions[i].answers[j]"}})],1)],1)],1)}),1)],1):_vm._e()],1)],1)}),1)]},proxy:true}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('Attempts',{key:calls,attrs:{"Quizz":Item}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }