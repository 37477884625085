<template>
  <ConfirmSave
      :color="color"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :confirmData="updateStatusActivatedData"
      @onConfirm="onUpdateStatusActivated"
  />
</template>

<script>
  import Quizzes from '@/services/quizzes'

  import ConfirmSave from '@/components/_Part/_Modal/_Save/Confirm'

  export default {
    name: 'UpdateStatusActivated',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdateStatusActivated'
    ],
    components: {
      ConfirmSave
    },
    data() {
      return {
        color: 'success',
        icon: 'mdi-toggle-switch',
        itemKey: 'Quizz'
      }
    },
    methods: {
      fetchData() {
        return Quizzes
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Aktivovať test'
      },
      setSubtitle(Item) {
        return Item.title
      },
      setAlert() {
        return 'Naozaj chcete aktivovať test?'
      },
      updateStatusActivatedData() {
        return Quizzes
          .updateStatusActivated({
            id: this.id,
            data: {}
          })
      },
      onUpdateStatusActivated(response) {
        this.$emit('onUpdateStatusActivated', response)
      }
    }
  }
</script>
