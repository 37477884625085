<template>
  <a
      @click.stop.prevent="setOrderBy"
  >
    <v-icon
        v-if="order === value"
    >
      <template
          v-if="by === 'ASC'"
      >
        mdi-chevron-up
      </template>

      <template
          v-else
      >
        mdi-chevron-down
      </template>
    </v-icon>

    {{ label }}
  </a>
</template>

<script>
  export default {
    name: 'OrderBy',
    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        type: String,
        required: true
      },
      order: {
        type: String,
        required: true
      },
      by: {
        type: String,
        required: true
      }
    },
    emits: [
      'setOrderBy'
    ],
    methods: {
      setOrderBy() {
        let order = this.value

        let by = 'ASC'

        if (this.order === this.value) {
          if (this.by === 'ASC') {
            by = 'DESC'
          } else {
            by = 'ASC'
          }
        }

        this.$emit('setOrderBy', order, by)
      }
    }
  }
</script>
