<template>
  <ConfirmSave
      :color="color"
      :icon="icon"
      :maxWidth="maxWidth"
      :buttonYes="buttonYes"
      :buttonNo="buttonNo"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :confirmData="deleteData"
      @onConfirm="onDelete"
  />
</template>

<script>
  import ConfirmSave from '@/components/_Part/_Modal/_Save/Confirm'

  export default {
    name: 'Delete',
    props: {
      color: {
        type: String,
        default: 'error'
      },
      icon: {
        type: String,
        default: 'mdi-delete'
      },
      maxWidth: {
        type: Number,
        default: 500
      },
      buttonYes: {
        type: String,
        default: 'Áno'
      },
      buttonNo: {
        type: String,
        default: 'Nie'
      },
      fetchData: {
        type: Function,
        required: true
      },
      itemKey: {
        type: String,
        required: true
      },
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setAlert: {
        type: Function,
        required: true
      },
      deleteData: {
        type: Function,
        required: true
      }
    },
    emits: [
      'onDelete'
    ],
    components: {
      ConfirmSave
    },
    methods: {
      onDelete(response) {
        this.$emit('onDelete', response)
      }
    }
  }
</script>
