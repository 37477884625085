import Vue from 'vue'

import Qs from 'qs'

class Admins {
  async search({params}) {
    return await Vue.axios
      .get('app/admins/search', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/admins', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/admins/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/admins/' + id, data)
  }

  async updateStatusCanceled({id, data}) {
    return await Vue.axios
      .put('app/admins/' + id + '/status/canceled', data)
  }

  async updateStatusRestored({id, data}) {
    return await Vue.axios
      .put('app/admins/' + id + '/status/restored', data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/admins/' + id)
  }
}

const admins = new Admins()

export default admins
