<template>
  <span
      :title="title"
      class="text-truncate"
      :style="{
        maxWidth: maxWidth
      }"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot/>
  </span>
</template>

<script>
  export default {
    name: 'Truncate',
    props: {
      title: {
        type: String,
        default: null
      },
      maxWidth: {
        type: String,
        default: '100%'
      }
    }
  }
</script>
