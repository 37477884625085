var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotBlankPage',{attrs:{"maxWidth":_vm.maxWidth,"icon":_vm.icon,"fetchData":_vm.fetchData,"itemKey":_vm.Item,"setTitle":_vm.setTitle,"setSubtitle":_vm.setSubtitle,"setBreadcrumbs":_vm.setBreadcrumbs,"failLink":_vm.failLink},scopedSlots:_vm._u([{key:"action",fn:function({
        Item,
        refresh
      }){return [_c('Update',{attrs:{"id":Item.id},on:{"onUpdate":refresh}}),(Item.deletable)?_c('Delete',{attrs:{"id":Item.id},on:{"onDelete":() => {
          _vm.$router.push('/volunteers')
        }}}):_vm._e()]}},{key:"default",fn:function({
        calls,
        Item
      }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CardPart',{staticClass:"grey lighten-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h3',[_vm._v(" E-mail ")]),_c('p',[_c('a',{attrs:{"href":'mailto: ' + Item.email}},[_vm._v(" "+_vm._s(Item.email)+" ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h3',[_vm._v(" Telefón ")]),_c('p',[_c('a',{attrs:{"href":'tel: ' + Item.phone}},[_vm._v(" "+_vm._s(Item.phone)+" ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h3',[_vm._v(" Dátum narodenia ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.birthDatePlain,'DD.MM.YYYY'))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h3',[_vm._v(" Primárne ")]),_c('p',[_vm._v(" "+_vm._s(Item.placePrimaryEnum)+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h3',[_vm._v(" Sekundárne miesto ")]),_c('ParagraphIsEmpty',{attrs:{"value":Item.placeSecondaryEnum}},[_vm._v(" "+_vm._s(Item.placeSecondaryEnum)+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h3',[_vm._v(" Stav ")]),_c('p',[_c('v-badge',{attrs:{"inline":true,"color":Item.statusColorEnum,"content":Item.statusEnum}}),(Item.note)?_c('v-tooltip',{attrs:{"bottom":true},scopedSlots:_vm._u([{key:"activator",fn:function({
                          on,
                          attrs
                        }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(Item.note)+" ")]):_vm._e()],1)])],1)]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Attempts',{key:calls,attrs:{"Volunteer":Item}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('UserHistories',{key:calls,attrs:{"User":Item}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }