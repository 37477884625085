<template>
  <FormPart
      :setItemForm="setItemForm"
      :submitData="submitData"
      @onSuccess="onSuccess"
  >
    <template
        v-slot:default="{
          loading,
          errors,
          ItemForm
        }"
    >
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.username"
              :error-messages="errors.username"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Prezývka"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ItemForm.plainPassword"
              :error-messages="errors.plainPassword"
              :filled="true"
              :dense="true"
              hide-details="auto"
              label="Heslo"
              type="password"
          />
        </v-col>

        <v-col
            cols="12"
        >
          <v-btn
              type="submit"
              :large="true"
              :block="true"
              :loading="loading"
              :disabled="loading"
              color="primary"
          >
            <v-icon>
              mdi-login
            </v-icon>

            Prihlásiť sa
          </v-btn>
        </v-col>

        <v-col
            cols="12"
            class="text-center"
        >
          <router-link
              to="/registration"
          >
            Zaregistrovať sa
          </router-link>
        </v-col>

        <v-col
            cols="12"
            class="text-center"
        >
          <router-link
              to="/new-password/restore"
          >
            Vyžiadať nové heslo
          </router-link>
        </v-col>
      </v-row>
    </template>
  </FormPart>
</template>

<script>
  import Security from '@/services/security'

  import FormPart from '@/components/_Part/Form'

  export default {
    name: 'Form',
    components: {
      FormPart
    },
    methods: {
      setItemForm() {
        return {
          username: null,
          plainPassword: null
        }
      },
      submitData(ItemForm) {
        return Security
          .login({
            data: ItemForm
          })
      },
      onSuccess() {
        this.$router.push('/dashboard')
      }
    }
  }
</script>
