<template>
  <BlankPage
      :setTitle="setTitle"
  >
    <template
        v-slot:default="{
          title
        }"
    >
      <GridPart
          :icon="icon"
          :title="title"
          :hasFilter="hasFilter"
          :colspan="colspan"
          :empty="empty"
          :defaultFilters="defaultFilters"
          :defaultOrder="defaultOrder"
          :defaultBy="defaultBy"
          :defaultLimit="defaultLimit"
          :fetchData="fetchData"
          :itemsKey="itemsKey"
      >
        <template
            v-slot:search="{
              params,
              errors,
              setSearch
            }"
        >
          <v-col
              cols="12"
              md="6"
          >
            <v-text-field
                v-model="params.query"
                :error-messages="errors.query"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Vyhľadávanie"
                :clearable="true"
                @change="setSearch"
            />
          </v-col>
        </template>

        <template
            v-slot:filter="{
              params,
              errors,
              setFilter
            }"
        >
          <v-col
              cols="12"
              sm="6"
              md="3"
          >
            <ComboboxRegisterIndexesIndexGroupValue
                v-model="params.Filters.method"
                :error-messages="errors.Filters?.method"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Metóda"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="audit_log_method"
            />
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="3"
          >
            <ComboboxRegisterIndexesIndexGroupValue
                v-model="params.Filters.statusCode"
                :error-messages="errors.Filters?.statusCode"
                :outlined="true"
                :dense="true"
                hide-details="auto"
                label="Stavový kód"
                :clearable="true"
                @change="setFilter"
                indexGroupValue="audit_log_status_code"
            />
          </v-col>
        </template>

        <template
            v-slot:list-head="{
              order,
              by,
              setOrderBy
            }"
        >
          <th>
            Uri
          </th>

          <th>
            Užívateľ
          </th>

          <th>
            <OrderByList
                label="Dátum a čas požiadavky"
                value="audlogdat.createdAt"
                :order="order"
                :by="by"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            Metóda
          </th>

          <th>
            <OrderByList
                label="Dátum a čas odpovede"
                value="audlogdat.updatedAt"
                :order="order"
                :by="by"
                @setOrderBy="setOrderBy"
            />
          </th>

          <th>
            Stavový kód
          </th>

          <th
              class="text-right fixed"
          />
        </template>

        <template
            v-slot:list-body-standard="{
              Item
            }"
        >
          <td>
            <a
                :href="Item.uri"
                target="_blank"
            >
              <TruncateHelper
                  :title="Item.uri"
                  maxWidth="300px"
              >
                {{ Item.uri }}
              </TruncateHelper>
            </a>
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.userName"
            >
              <TruncateHelper
                  :title="Item.userName"
                  maxWidth="300px"
              >
                {{ Item.userName }}
              </TruncateHelper>
            </SpanIsEmpty>
          </td>

          <td>
            {{ Item.createdAt|moment('DD.MM.YYYY HH:mm') }}
          </td>

          <td>
            {{ Item.method }}
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.updatedAt"
            >
              {{ Item.updatedAt|moment('DD.MM.YYYY HH:mm') }}
            </SpanIsEmpty>
          </td>

          <td>
            <SpanIsEmpty
                :value="Item.statusCode"
            >
              {{ Item.statusCode }}
            </SpanIsEmpty>
          </td>

          <td
              class="text-right fixed"
          >
            <v-btn
                :icon="true"
                :outlined="true"
                color="primary"
                :to="'/audit-logs/' + Item.id"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </td>
        </template>

        <template
            v-slot:pagination-count="{
              limit,
              setLimit
            }"
        >
          <LimitPagination
              v-for="(_limit, i) in [10, 25, 50, 100]"
              :key="i"
              :label="_limit"
              :value="_limit"
              :limit="limit"
              @setLimit="setLimit"
          />
        </template>
      </GridPart>
    </template>
  </BlankPage>
</template>

<script>
  import AuditLogs from '@/services/audit-logs'

  import BlankPage from '@/components/_Part/_Page/Blank'

  import GridPart from '@/components/_Part/Grid'

  import OrderByList from '@/components/_Part/_Grid/_List/OrderBy'

  import LimitPagination from '@/components/_Part/_Grid/_Pagination/Limit'

  import TruncateHelper from '@/components/_Part/_Helper/Truncate'

  import SpanIsEmpty from '@/components/_Part/_Helper/_IsEmpty/Span'

  import ComboboxRegisterIndexesIndexGroupValue from '@/components/Register/ComboboxIndexesIndexGroupValue'

  export default {
    name: 'AuditLogs',
    components: {
      BlankPage,
      GridPart,
      OrderByList,
      LimitPagination,
      TruncateHelper,
      SpanIsEmpty,
      ComboboxRegisterIndexesIndexGroupValue
    },
    data() {
      return {
        icon: 'mdi-database',
        hasFilter: true,
        colspan: 7,
        empty: 'Nenašli sa žiadne audit logy.',
        itemsKey: 'auditLogs',
        defaultFilters: {
          method:  null,
          statusCode: null
        },
        defaultOrder: 'audlogdat.createdAt',
        defaultBy: 'DESC',
        defaultLimit: 25
      }
    },
    methods: {
      fetchData(params) {
        return AuditLogs
          .search({
            params: params
          })
      },
      setTitle() {
        return 'Audit logy'
      }
    }
  }
</script>
