<template>
  <a
      @click.stop.prevent="setLimit"
  >
    <template
        v-if="limit === value"
    >
      <v-icon>
        mdi-target
      </v-icon>
    </template>

    {{ label }}
  </a>
</template>

<script>
  export default {
    name: 'Limit',
    props: {
      label: {
        type: Number,
        required: true
      },
      value: {
        type: Number,
        required: true
      },
      limit: {
        type: Number,
        required: true
      }
    },
    emits: [
      'setLimit'
    ],
    methods: {
      setLimit() {
        let limit = this.value

        this.$emit('setLimit', limit)
      }
    }
  }
</script>
