<template>
  <SelectField
      :name="name"
      :fetchData="fetchData"
      :itemsKey="itemsKey"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template
        v-slot:action="{
          init
        }"
    >
      <slot
          name="action"
          :init="init"
      />
    </template>
  </SelectField>
</template>

<script>
  import SelectField from '@/components/_Part/_Field/Select'

  export default {
    name: 'Autocomplete',
    props: {
      fetchData: {
        type: Function,
        required: true
      },
      itemsKey: {
        type: String,
        required: true
      }
    },
    components: {
      SelectField
    },
    data() {
      return {
        name: 'v-autocomplete'
      }
    }
  }
</script>
