var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlankPage',{attrs:{"setTitle":_vm.setTitle},scopedSlots:_vm._u([{key:"default",fn:function({
        title
      }){return [_c('GridPart',{attrs:{"icon":_vm.icon,"title":title,"colspan":_vm.colspan,"empty":_vm.empty,"defaultFilters":_vm.defaultFilters,"defaultOrder":_vm.defaultOrder,"defaultBy":_vm.defaultBy,"defaultLimit":_vm.defaultLimit,"fetchData":_vm.fetchData,"itemsKey":_vm.itemsKey},scopedSlots:_vm._u([{key:"search",fn:function({
            params,
            errors,
            setSearch
          }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","xl":"3"}},[_c('v-text-field',{attrs:{"error-messages":errors.query,"outlined":true,"dense":true,"hide-details":"auto","label":"Vyhľadávanie","clearable":true},on:{"change":setSearch},model:{value:(params.query),callback:function ($$v) {_vm.$set(params, "query", $$v)},expression:"params.query"}})],1)]}},{key:"filter",fn:function({
            params,
            errors,
            setFilter
          }){return [_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('ComboboxRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.dir,"outlined":true,"dense":true,"hide-details":"auto","label":"Priečinok","clearable":true,"indexGroupValue":"file_dir"},on:{"change":setFilter},model:{value:(params.Filters.dir),callback:function ($$v) {_vm.$set(params.Filters, "dir", $$v)},expression:"params.Filters.dir"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('ComboboxRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.type,"outlined":true,"dense":true,"hide-details":"auto","label":"Typ","clearable":true,"indexGroupValue":"file_type"},on:{"change":setFilter},model:{value:(params.Filters.type),callback:function ($$v) {_vm.$set(params.Filters, "type", $$v)},expression:"params.Filters.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('AutocompleteRegisterIndexesIndexGroupValue',{attrs:{"error-messages":errors.Filters?.status,"outlined":true,"dense":true,"hide-details":"auto","label":"Stav","clearable":true,"indexGroupValue":"file_status"},on:{"change":setFilter},model:{value:(params.Filters.status),callback:function ($$v) {_vm.$set(params.Filters, "status", $$v)},expression:"params.Filters.status"}})],1)]}},{key:"list-head",fn:function({
            order,
            by,
            orderList,
            byList,
            setOrderBy
          }){return [_c('th',[_vm._v(" Priečinok ")]),_c('th',[_vm._v(" Názov ")]),_c('th',[_vm._v(" Typ ")]),_c('th',[_vm._v(" Stav ")]),_c('th',[_c('OrderByList',{attrs:{"label":"Dátum a čas zadania","value":"fildat.createdAt","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',[_c('OrderByList',{attrs:{"label":"Dátum a čas nahratia","value":"fildat.updatedAt","order":order,"by":by},on:{"setOrderBy":setOrderBy}})],1),_c('th',{staticClass:"text-right fixed"})]}},{key:"list-body-standard",fn:function({
            Item
          }){return [_c('td',[_vm._v(" "+_vm._s(Item.dir)+" ")]),_c('td',[_c('a',{attrs:{"href":_vm.$global.cdnUrl() + 'media/files/by-token/' + Item.token,"target":"_blank"}},[_c('TruncateHelper',{attrs:{"title":Item.name,"maxWidth":"300px"}},[_vm._v(" "+_vm._s(Item.name)+" ")])],1)]),_c('td',[_vm._v(" "+_vm._s(Item.type)+" ")]),_c('td',[_c('v-badge',{attrs:{"inline":true,"color":Item.statusColorEnum,"content":Item.statusEnum}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(Item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]),_c('td',[_c('SpanIsEmpty',{attrs:{"value":Item.updatedAt}},[_vm._v(" "+_vm._s(_vm._f("moment")(Item.updatedAt,'DD.MM.YYYY HH:mm'))+" ")])],1),_c('td',{staticClass:"text-right fixed"},[_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/files/' + Item.id}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1)]}},{key:"pagination-count",fn:function({
            limit,
            setLimit
          }){return _vm._l(([10, 25, 50, 100]),function(_limit,i){return _c('LimitPagination',{key:i,attrs:{"label":_limit,"value":_limit,"limit":limit},on:{"setLimit":setLimit}})})}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }