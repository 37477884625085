<template>
  <IsEmptyHelper
      :value="value"
      :ifNo="ifNo"
      :ifYes="ifYes"
      :empty="empty"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot/>
  </IsEmptyHelper>
</template>

<script>
  import IsEmptyHelper from '@/components/_Part/_Helper/IsEmpty'

  export default {
    name: 'Columns',
    props: {
      value: {
        required: true
      },
      empty: {
        type: String,
        default: 'žiadne'
      }
    },
    components: {
      IsEmptyHelper
    },
    data() {
      return {
        ifNo: 'v-row',
        ifYes: 'p'
      }
    }
  }
</script>
