<template>
  <ConfirmSave
      :color="color"
      :icon="icon"
      :fetchData="fetchData"
      :itemKey="itemKey"
      :setTitle="setTitle"
      :setSubtitle="setSubtitle"
      :setAlert="setAlert"
      :confirmData="updateStatusDeactivatedData"
      @onConfirm="onUpdateStatusDeactivated"
  />
</template>

<script>
  import Courses from '@/services/courses'

  import ConfirmSave from '@/components/_Part/_Modal/_Save/Confirm'

  export default {
    name: 'UpdateStatusDeactivated',
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    emits: [
      'onUpdateStatusDeactivated'
    ],
    components: {
      ConfirmSave
    },
    data() {
      return {
        color: 'error',
        icon: 'mdi-toggle-switch-off',
        itemKey: 'Course'
      }
    },
    methods: {
      fetchData() {
        return Courses
          .read({
            id: this.id
          })
      },
      setTitle() {
        return 'Deaktivovať školenie'
      },
      setSubtitle(Item) {
        return Item.title
      },
      setAlert() {
        return 'Naozaj chcete deaktivovať školenie?'
      },
      updateStatusDeactivatedData() {
        return Courses
          .updateStatusDeactivated({
            id: this.id,
            data: {}
          })
      },
      onUpdateStatusDeactivated(response) {
        this.$emit('onUpdateStatusDeactivated', response)
      }
    }
  }
</script>
