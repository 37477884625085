<template>
  <ConfirmSave
      :color="color"
      :icon="icon"
      :setTitle="setTitle"
      :setAlert="setAlert"
      :confirmData="logoutData"
      @onConfirm="onLogout"
  />
</template>

<script>
  import Security from '@/services/security'

  import ConfirmSave from '@/components/_Part/_Modal/_Save/Confirm'

  export default {
    name: 'Logout',
    components: {
      ConfirmSave
    },
    data() {
      return {
        color: 'primary',
        icon: 'mdi-logout'
      }
    },
    methods: {
      setTitle() {
        return 'Odhlásiť sa'
      },
      setAlert() {
        return 'Naozaj sa chcete odhlásiť?'
      },
      logoutData() {
        return Security
          .logout()
      },
      onLogout() {
        this.$router.push('/login')
      }
    }
  }
</script>
