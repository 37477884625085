<template>
  <v-col
      cols="12"
  >
    <v-progress-linear
        v-if="loading"
        :indeterminate="true"
    />

    <v-simple-table>
      <template
          v-slot:default
      >
        <thead>
          <tr>
            <slot
                name="head"
                :setOrderBy="setOrderBy"
            />
          </tr>
        </thead>

        <tbody>
          <template
              v-if="items.length > 0"
          >
            <template
                v-for="(_Item) in items"
            >
              <tr
                  v-if="hasStandard"
                  :key="_Item.id + 'standard'"
                  :class="'standard' + standardClass(_Item)"
              >
                <slot
                    name="body-standard"
                    :Item="_Item"
                />
              </tr>

              <tr
                  v-if="hasExtra"
                  :key="_Item.id + 'extra'"
                  :class="'extra' + extraClass(_Item)"
              >
                <slot
                    name="body-extra"
                    :Item="_Item"
                />
              </tr>
            </template>
          </template>

          <tr
              v-else
          >
            <td
                :colspan="colspan"
                class="text-center"
            >
              {{ empty }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>

<script>
  export default {
    name: 'List',
    props: {
      loading: {
        type: Boolean,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      hasStandard: {
        type: Boolean,
        default: true
      },
      standardClass: {
        type: Function,
        default: () => ' '
      },
      hasExtra: {
        type: Boolean,
        default: false
      },
      extraClass: {
        type: Function,
        default: () => ' '
      },
      colspan: {
        type: Number,
        required: true
      },
      empty: {
        type: String,
        required: true
      }
    },
    emits: [
      'setParams'
    ],
    methods: {
      setOrderBy(order, by) {
        this.$emit('setParams', {
          order: order,
          by: by
        })
      }
    }
  }
</script>
