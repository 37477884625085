<template>
  <v-row
      v-if="content"
  >
    <v-col
        cols="12"
    >
      <slot
          name="default"
          :loading="loading"
          :calls="calls"
          :Item="Item"
          :title="title"
          :subtitle="subtitle"
          :backLink="backLink"
          :refresh="refresh"
          :reload="reload"
      />
    </v-col>
  </v-row>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  export default {
    name: 'Page',
    props: {
      fetchData: {
        type: Function,
        default: null
      },
      itemKey: {
        type: String,
        default: null
      },
      setTitle: {
        type: Function,
        default: () => null
      },
      setSubtitle: {
        type: Function,
        default: () => null
      },
      setBreadcrumbs: {
        type: Function,
        default: () => []
      }
    },
    emits: [
      'onSuccess',
      'onFail'
    ],
    data() {
      return {
        content: false,
        loading: false,
        calls: 0,
        Item: null,
        title: null,
        subtitle: null,
        breadcrumbs: []
      }
    },
    metaInfo() {
      return {
        title: this.title
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      backLink: function() {
        return (
          this.breadcrumbs.length > 0
            ? this.breadcrumbs.at(-1).to
            : '/'
        )
      }
    },
    methods: {
      init() {
        this.initData()
      },
      refresh() {
        this.refreshData()
      },
      reload() {
        this.reloadData()
      },
      initData() {
        this.prepareData('content', false, true)
      },
      refreshData() {
        this.prepareData('loading', true, true)
      },
      reloadData() {
        this.prepareData('loading', true, false)
      },
      storeBreadcrumbs() {
        this.$store.dispatch(
          'layout/setBreadcrumbs',
          {
            breadcrumbs: [
              {
                text: 'Systém',
                to: '/',
                exact: true
              },
              ...this.breadcrumbs,
              {
                text: this.title,
                to: this.$route.path,
                exact: true
              }
            ]
          }
        )
      },
      prepareData(key, value, increase) {
        if (this.fetchData) {
          this[key] = value

          this.fetchData()
            .then((response) => {
              this.Item = response.data.data[this.itemKey]

              this.title = this.setTitle(this.Item)

              this.subtitle = this.setSubtitle(this.Item)

              this.breadcrumbs = this.setBreadcrumbs(this.Item)

              this.storeBreadcrumbs()

              this.$emit('onSuccess', response)
            })
            .catch((error) => {
              this.$store.dispatch('layout/openSnackbar', {
                message: error.response.data.message
              })

              this.$emit('onFail', error.response)
            })
            .finally(() => {
              this[key] = !value

              if (increase) {
                this.calls++
              }
            })
        } else {
          this[key] = value

          this.title = this.setTitle()

          this.subtitle = this.setSubtitle()

          this.breadcrumbs = this.setBreadcrumbs()

          this.storeBreadcrumbs()

          this[key] = !value

          if (increase) {
            this.calls++
          }
        }
      }
    }
  }
</script>
