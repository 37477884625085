<template>
  <AutomcompleteSelect
      :fetchData="fetchData"
      itemsKey="indexGroups"
      v-bind="{
        ...$attrs,
        itemValue: 'id',
        itemText: 'title'
      }"
      v-on="$listeners"
  />
</template>

<script>
  import IndexGroups from '@/services/index-groups'

  import AutomcompleteSelect from '@/components/_Part/_Field/_Select/Autocomplete'

  export default {
    name: 'Autocomplete',
    components: {
      AutomcompleteSelect
    },
    methods: {
      fetchData() {
        return IndexGroups
          .index()
      }
    }
  }
</script>
